import React, { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, Row, Col, Typography } from 'antd'
import { getStaticText } from '../../../../../utils'
import { columns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getOrderList } from '../services'
import OrdersForm from '../Edit/ui'
import { GridContextProvider, PagingTable } from '../../../../../components'
import Filters from './Filters'

const { Text } = Typography

export default () => {
  const [gridData, setGridData] = useState(initGridData)
  const location = useLocation()

  const onLoad = useCallback(() => {
    getOrderList(setGridData)(new URLSearchParams(location.search))
  }, [location, setGridData])

  return (
    <GridContextProvider onLoad={onLoad}>
      <Filters />
      <Card>
        <Row>
          <Col span={24}>
            <Text>{getStaticText('component.table.header.title')}</Text>
          </Col>
        </Row>
      </Card>
      <PagingTable
        columns={columns}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.page}
        pageSize={gridData.page_size}
      />
      <OrdersForm />
    </GridContextProvider>
  )
}
