import request from './request'

export const getMonthlySales = (data) =>
  request.post('/backend/monthly-sales/dashboard', data)
export const editMonthlySales = (data) =>
  request.post('/backend/monthly-sales/edit', data)
export const exportMonthlySales = (params) =>
  request.get('/backend/monthly-sales/export', { params })
export const exportMonthlySalesTemplate = () =>
  request.get('/backend/monthly-sales/export-template')
