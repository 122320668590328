import { PostGrid, BannerGrid, TagsGrid, WordsGrid } from '../pages'

const bbs = {
  path: '/bbs',
  primary: 'app.sider.bbs',
  hideGlobalTips: true,
  routes: [
    {
      path: '/bbs/banner',
      modular: 'crm',
      permission: 'bbs/banner',
      primary: 'app.sider.bbs.banner',
      component: BannerGrid,
      exact: true
    },
    {
      path: '/bbs/post',
      modular: 'crm',
      permission: 'bbs/discussion',
      primary: 'app.sider.bbs.post',
      component: PostGrid,
      exact: true
    },
    {
      path: '/bbs/tags',
      modular: 'crm',
      permission: 'bbs/discussion_tags',
      primary: 'app.sider.bbs.tags',
      component: TagsGrid,
      exact: true
    },
    {
      path: '/bbs/words',
      modular: 'crm',
      permission: 'bbs/filter',
      primary: 'app.sider.bbs.words',
      component: WordsGrid,
      exact: true
    }
  ]
}

export default bbs
