export default {
  'app.login.title': 'Xpark中台报表系统',
  'app.login.form.username': '账号',
  'app.login.form.username.warn.text': '请输入账号',
  'app.login.form.password': '密码',
  'app.login.form.password.warn.text': '请输入密码',
  'app.login.button.text': '登录',
  'app.login.message.success': '登录成功',

  'app.logout.message.success': '退出登录成功',

  'app.sider.logo.index': '首页',
  'app.sider.logo.crm': '会员中台',
  'app.sider.logo.market': '营销中台',
  'app.sider.logo.merchant': '商家中台',
  'app.sider.logo.report': '数据中台',
  'app.sider.logo.config': '基础后台',
  'app.sider.logo.text': '数据银行',
  'app.sider.sub.menu.text': '电商销售报表',
  'app.sider.daily.sales': '日报表',
  'app.sider.weekly.sales': '周报表',
  'app.sider.monthly.sales': '月报表',
  'app.sider.data.maintenance': '数据维护',
  'app.sider.weekly.management': '周-人群经营',
  'app.sider.review.menu': '评论',
  'app.sider.review.list': '评论列表',
  'app.sider.people-yard': '人货场',
  'app.sider.daily.data.fill': '每日数据填写',
  'app.sider.total-report.big-query': '决策模型',
  'app.sider.rbac': '用户及权限',
  'app.sider.rbac.user': '用户管理',
  'app.sider.rbac.role': '角色管理',
  'app.sider.rbac.permissions': '权限管理',
  'app.sider.bbs': 'BBS',
  'app.sider.bbs.post': '帖子管理',
  'app.sider.bbs.banner': 'Banner 管理',
  'app.sider.bbs.tags': '分类管理',
  'app.sider.bbs.words': '敏感词管理',
  'app.sider.bbs.product': '关联产品',
  'app.sider.system': '外挂系统',
  'app.sider.luckyspin': '大转盘',
  'app.sider.luckyspin.activity': '活动列表',
  'app.sider.system.crowd-funding': '众筹管理',
  'app.sider.crowd-funding.activity': '活动列表',
  'app.sider.crowd-funding.orders': '订单列表',
  'app.sider.system.rewrite': 'URL 管理',
  'app.sider.report': '电商销售报表',
  'app.sider.weekly.sales.dashboard': '总览',
  'app.sider.daily.sales.dashboard': '总览',
  'app.sider.index': '首页',
  'app.sider.monthly.sales.dashboard': '总览',
  'app.sider.user-operation': '用户运营',
  'app.sider.user-operation.chunyin': '传音登陆',
  'app.sider.user-operation.other': 'FB/Google登录',
  'app.sider.user-operation.touch': '用户触达',
  'app.sider.crm': 'CRM',
  'app.sider.game': '互动游戏',
  'app.sider.recharge': '充值',
  'app.sider.share': '分享',
  'app.sider.share.cut': '砍一刀',
  'app.sider.share.group-buy': '团购',
  'app.sider.advance': '预付',
  'app.sider.fission': '裂变',
  'app.sider.net-red': '网红',
  'app.sider.merchant': '商家管理',
  'app.sider.merchant.ng': '尼日利亚',
  'app.sider.merchant.eg': '埃及',
  'app.sider.merchant.pk': '巴基斯坦',
  'app.sider.merchant.ke': '肯尼亚',
  'app.sider.merchant.ma': '摩洛哥',
  'app.sider.merchant.gh': '加纳',

  'app.footer.copyright': 'Copyright ©',
  'app.footer.company': 'XPARK',
  'app.footer.statement': '保留所有权利。',
  'app.tips': '提示',

  'component.app.header.avatar.text': 'Xpark',
  'component.app.header.menu.logout': '退出',

  'component.search': '搜索:',
  'component.search.country.text': '国家:',
  'component.search.country.type': '模型:',
  'component.search.pattern': '决策类型:',
  'component.search.deviceType': '活动名称:',
  'component.search.platform.text': '电商平台:',
  'component.search.time.text': '日期:',
  'component.search.button.filter': '筛选',
  'component.search.button.reset': '重置',
  'component.search.review.text': '评分:',

  'component.table.header.title': '查询结果',
  'component.table.header.title.promotion': '促销列表',

  'component.edit.modal.title.daily.sales': '每日电商报表数据',
  'component.edit.modal.title.weekly.sales': '每周电商报表数据',
  'component.edit.modal.title.monthly.sales': '每月电商报表数据',
  'component.edit.modal.ok.text': '保存',
  'component.edit.modal.cancel.text': '取消',

  'component.table.import.text': '导入',
  'component.table.template.text': '模板下载',
  'component.table.export.text': '导出',
  'component.table.export.month.text': '月报表导出',
  'component.table.export.week.text': '周报表导出',
  'component.table.add.text': '新增',
  'component.table.export_budget_template': '预算模板',
  'component.table.import_budget': '导入年度预算',

  'component.remarks.data.source_note': '来源：',
  'component.remarks.data.source.system.generation_note': '系统生成',
  'component.remarks.data.source.fill.inmanually_note': '手动填写',
  'component.remarks.data.source.calculated.results_note': '计算所得',
  'component.remarks.data.source.data.capture_note': '数据抓取',
  'component.remarks.data.source.data.processing_note': '数据处理',
  'component.remarks.data.source.budget.import': '预算导入',

  'table.operating': '操作',
  'table.operating.button.text': '编辑',

  'daily.sales.table.country': '国家',
  'daily.sales.table.report_date': '日期',
  'daily.sales.table.platform': '电商平台',
  'daily.sales.table.promotion_text': '促销活动内容',
  'daily.sales.table.platform_resource_used': '电商平台资源位',
  'daily.sales.table.discounts': '店铺利益点',
  'daily.sales.table.day.sales.amount': '日销售金额',
  'daily.sales.table.sales_amount': '成交金额GMV $',
  'daily.sales.table.sales_amount_last_year': '去年同期店铺销售金额 $',
  'daily.sales.table.sales_amount_year_on_year': '同比',
  'daily.sales.table.sales_amount_year_on_year_calculation_note':
    '计算公式：成交金额GMV / 去年同期店铺销售金额 -1',
  'daily.sales.table.monthly.sales.completion': '月销售完成情况',
  'daily.sales.table.sales_volume_accumation': '累计订单量',
  'daily.sales.table.sales_volume_accumation_explain_note':
    '说明：本月截止当日累计订单数',
  'daily.sales.table.sales_volume_target': '订单量目标',
  'daily.sales.table.sales_volume_completion_rate': '累计完成率',
  'daily.sales.table.sales_volume_completion_rate_calculation_note':
    '计算公式：累计订单量 / 订单量目标',
  'daily.sales.table.sales_amount_accumation': '累计订单金额',
  'daily.sales.table.sales_amount_target': '目标预计金额',
  'daily.sales.table.sales_amount_completion_rate': '订单金额完成率',
  'daily.sales.table.sales_amount_completion_rate_calculation_note':
    '计算公式：累计订单金额 / 目标预计金额',
  'daily.sales.table.flow': '流量',
  'daily.sales.table.uv': '访客数UV',
  'daily.sales.table.uv_last_year': '去年同期UV',
  'daily.sales.table.uv_percent': '同比',
  'daily.sales.table.uv_percent_calculation_note':
    '计算公式：访客数UV / 去年同期UV -1',
  'daily.sales.table.pv': '浏览量PV',
  'daily.sales.table.conversion.rate': '转化率',
  'daily.sales.table.conversion_rate': '转化率 CVR',
  'daily.sales.table.conversion_rate_calculation_note':
    '计算公式：订单数 / 访客数UV',
  'daily.sales.table.conversion_rate_last_year': '去年同期转化率',
  'daily.sales.table.conversion_rate_last_year_calculation_note':
    '计算公式：去年同期订单数 / 去年同期UV',
  'daily.sales.table.conversion_rate_year_on_year': '同比',
  'daily.sales.table.conversion_rate_year_on_year_calculation_note':
    '计算公式：转化率 CVR / 去年同期转化率 -1',
  'daily.sales.table.customer.price': '客单价',
  'daily.sales.table.customer_order_average': '订单均价$',
  'daily.sales.table.customer_order_average_calculation_note':
    '计算公式：订单金额 / 订单数',
  'daily.sales.table.customer_order_last_year_average': '去年同期订单均价$',
  'daily.sales.table.customer_order_last_year_average_calculation_note':
    '计算公式：去年同期店铺销售金额 / 去年同期订单数',
  'daily.sales.table.customer_order_year_on_year': '同比',
  'daily.sales.table.customer_order_year_on_year_calculation_note':
    '计算公式：订单均价 / 去年同期订单均价 -1',
  'daily.sales.table.uv.worth': 'uv价值',
  'daily.sales.table.uv_worth': '$',
  'daily.sales.table.uv_worth_calculation_note':
    '计算公式：成交金额GMV / 访客数UV',
  'daily.sales.table.order': '订单',
  'daily.sales.table.orders_amount': '订单金额',
  'daily.sales.table.orders': '订单数',
  'daily.sales.table.orders_average': '订单均价',
  'daily.sales.table.orders_average_calculation_note':
    '计算公式：订单金额 / 订单数',
  'daily.sales.table.orders_last_year': '去年同期订单数',
  'daily.sales.table.orders_year_on_year': '订单数同比',
  'daily.sales.table.orders_year_on_year_calculation_note':
    '计算公式：订单数 / 去年同期订单数 -1',
  'daily.sales.table.online_paid_orders': '先款后货订单数',
  'daily.sales.table.online_paid_orders_explain_note': '说明：线上支付订单数',
  'daily.sales.table.online_paid_orders_percent': '先款后货比例',
  'daily.sales.table.online_paid_orders_percent_calculation_note':
    '计算公式：先款后货订单数 / 订单数',
  'daily.sales.table.cod_orders': 'COD订单数',
  'daily.sales.table.cod_orders_explain_note':
    '说明：支付方式为cashondelivery的订单数',
  'daily.sales.table.cod_orders_percent': 'COD比例',
  'daily.sales.table.cod_orders_percent_calculation_note':
    '计算公式：COD订单数 / 订单数',
  'daily.sales.table.cod_return_orders': 'COD退款订单数',
  'daily.sales.table.cod_return_orders_explain_note':
    '说明：支付方式为cashondelivery的退款订单数',
  'daily.sales.table.cod_return_orders_percent': 'COD退款率',
  'daily.sales.table.cod_return_orders_percent_calculation_note':
    '计算公式：COD退款订单数 / COD订单数',
  'daily.sales.table.phone.sales': '手机销量',
  'daily.sales.table.phone_sales_amount': '销售金额$',
  'daily.sales.table.phone_sales_amount_explain_note': '说明：手机类目获取',
  'daily.sales.table.phone_sales_volume': '销量',
  'daily.sales.table.phone_sales_volume_explain_note': '说明：手机类目获取',
  'daily.sales.table.phone_sales_average': '销售均价$',
  'daily.sales.table.phone_sales_average_calculation_note':
    '计算公式：手机销售金额$ / 销量',
  'daily.sales.table.product.supply': '产品供应',
  'daily.sales.table.total_online_product': '总上架产品数',
  'daily.sales.table.total_online_product_explain_note':
    '说明：至本日止，status为enable的产品数累加',
  'daily.sales.table.new_online_product': '新增产品数',
  'daily.sales.table.active_product': '活跃产品数',
  'daily.sales.table.active_product_explain_note': '说明：有销售的产品数',
  'daily.sales.table.active_product_percent': '活跃比例%',
  'daily.sales.table.active_product_percent_calculation_note':
    '计算公式：活跃产品数 / 总上架产品数',
  'daily.sales.table.score': '店铺评分',
  'daily.sales.table.ad.fb': '广告-FB',
  'daily.sales.table.ad_fb_consumption': '实际消耗金额$',
  'daily.sales.table.ad_fb_sales_amount': '直链销售金额$',
  'daily.sales.table.ad_fb_roi': 'ROI',
  'daily.sales.table.ad_fb_roi_calculation_note':
    '计算公式：直链销售金额$ / 实际消耗金额$',
  'daily.sales.table.ad.google': '广告-Google',
  'daily.sales.table.ad_google_consumption': '实际消耗金额$',
  'daily.sales.table.ad_google_sales_amount': '直链销售金额$',
  'daily.sales.table.ad_google_roi': 'ROI',
  'daily.sales.table.ad_google_roi_calculation_note':
    '计算公式：直链销售金额$ / 实际消耗金额$',
  'daily.sales.table.dm': 'DM',
  'daily.sales.table.dm_amount': 'DM',
  'daily.sales.table.dm_amount_calculation_note':
    '计算公式：FB实际消耗金额$ + Google实际消耗金额$',
  'daily.sales.table.dm_gmv_percent': 'DM/GMV',
  'daily.sales.table.dm_gmv_percent_calculation_note':
    '计算公式：DM / 成交金额GMV(两天前)',
  'daily.sales.table.ad.uv.amount': '广告引入UV',
  'daily.sales.table.ad_uv': '广告共引入UV',
  'daily.sales.table.ad_uv_percent': '广告共引入UV占比',
  'daily.sales.table.ad_uv_percent_calculation_note':
    '计算公式：广告共引入UV / 访客数UV',
  'daily.sales.review.title': '评论标题',
  'daily.sales.review.detail': '评论详情',
  'daily.sales.review.nickname': '用户名',
  'daily.sales.review.product_name': '产品名字',
  'daily.sales.review.rating': '评分',
  'daily.sales.review.pp_user': 'PP商',
  'daily.sales.review.country_code': '国家',
  'daily.sales.review.status': '评论状态',
  'daily.sales.review.created_at': '评论日期',
  'daily.sales.table.download_amount': '本日下载量',
  'daily.sales.table.app_activation_number': '预装APP激活数',
  'daily.sales.table.app_active_users': 'APP用户活跃数',
  'daily.sales.table.accumu_preassem_count': '累计下载量',
  'daily.sales.table.current_month_preassem_count': '本月下载量',
  'daily.sales.table.active_users': 'xpark活跃用户数',
  'daily.sales.table.all_active_users': 'ga+app活跃用户数',

  'weekly.sales.table.country': '国家',
  'weekly.sales.table.week_key': '周',
  'weekly.sales.table.platform': '电商平台',
  'weekly.sales.table.month': '月/周',
  'weekly.sales.table.promotion_text': '促销活动内容',
  'weekly.sales.table.platform_resource_used': '电商平台资源位',
  'weekly.sales.table.discounts': '店铺利益点',
  'weekly.sales.table.sales.amount': '销售金额',
  'weekly.sales.table.sales_amount': '成交金额GMV $',
  'weekly.sales.table.sales_amount_percent': '成交金额GMV全球占比',
  'weekly.sales.table.sales_amount_percent_note':
    '计算公式：成交金额GMV / 全球成交金额GMV',
  'weekly.sales.table.sales_amount_chain_ratio': '环比',
  'weekly.sales.table.sales_amount_chain_ratio_calculation_note':
    '计算公式：成交金额GMV / 上周成交金额GMV -1',
  'weekly.sales.table.monthly.sales.completion': '月销售完成情况',
  'weekly.sales.table.month_sales_volume_accumation': '累计订单量',
  'weekly.sales.table.month_sales_volume_target': '订单量目标',
  'weekly.sales.table.month_sales_volume_completion_rate': '累计完成率',
  'weekly.sales.table.month_sales_volume_completion_rate_calculation_note':
    '计算公式：累计订单量 / 订单量目标',
  'weekly.sales.table.flow': '流量',
  'weekly.sales.table.uv': '访客数UV',
  'weekly.sales.table.uv_chain_ratio': '环比',
  'weekly.sales.table.uv_chain_ratio_calculation_note':
    '计算公式：访客数UV / 上周访客数UV -1',
  'weekly.sales.table.conversion.rate': '转化率',
  'weekly.sales.table.conversion_rate': '转化率 CVR',
  'weekly.sales.table.conversion_rate_calculation_note':
    '计算公式：订单数 / 访客数UV',
  'weekly.sales.table.conversion_rate_chain_ratio': '环比',
  'weekly.sales.table.conversion_rate_chain_ratio_calculation_note':
    '计算公式：转化率 CVR / 上周转化率 CVR -1',
  'weekly.sales.table.customer.price': '客单价',
  'weekly.sales.table.customer_order_average': '订单均价$',
  'weekly.sales.table.customer_order_average_calculation_note':
    '计算公式：成交金额GMV / 订单数',
  'weekly.sales.table.customer_order_chain_ratio': '环比',
  'weekly.sales.table.customer_order_chain_ratio_calculation_note':
    '计算公式：订单均价 / 上周订单均价 -1',
  'weekly.sales.table.uv.worth': 'uv价值',
  'weekly.sales.table.uv_worth_1': '$',
  'weekly.sales.table.uv_worth_1_calculation_note':
    '计算公式：成交金额GMV / 访客数UV',
  'weekly.sales.table.uv_worth_2': '$',
  'weekly.sales.table.uv_worth_2_calculation_note':
    '计算公式：转化率 CVR * 客单价订单均价',
  'weekly.sales.table.total_users': 'Xpark注册用户总人数',
  'weekly.sales.table.APP.data': 'APP数据',
  'weekly.sales.table.download_amount': '本周下载量',
  'weekly.sales.table.accumu_preassem_count': '累计下载量',
  'weekly.sales.table.app_use_time': '原生APP使用时长',
  'weekly.sales.table.app_active_users': 'APP周活跃用户数',
  'weekly.sales.table.month_app_active_users': 'APP月活跃用户数',
  'weekly.sales.table.xpark_all_use_time': '总使用时长（秒）',
  'weekly.sales.table.active_users': '本周活跃用户数',
  'weekly.sales.table.all_active_users': 'ga+app活跃用户数',
  'weekly.sales.table.day_left': '留存',
  'weekly.sales.table.retention_rate': '留存率',
  'weekly.sales.table.order': '订单',
  'weekly.sales.table.orders_amount': '订单金额',
  'weekly.sales.table.total_orders': '订单数',
  'weekly.sales.table.orders_average': '订单均价',
  'weekly.sales.table.orders_average_calculation_note':
    '计算公式：订单金额 / 订单数',
  'weekly.sales.table.total_non_infinix': '非Infinix品牌订单数',
  'weekly.sales.table.total_non_infinix_percent': '非Infinix品牌订单占比',
  'weekly.sales.table.total_non_infinix_percent_calculation_note':
    '计算公式：非Infinix品牌订单数 / 订单数',
  'weekly.sales.table.total_last_year': '去年同期订单数',
  'weekly.sales.table.total_orders_year_on_year': '订单数同比',
  'weekly.sales.table.total_orders_year_on_year_calculation_note':
    '计算方式：订单数 / 去年同期订单数 -1',
  'weekly.sales.table.total_online_paid': '先款后货订单数',
  'weekly.sales.table.total_online_paid_explain_note': '说明：线上支付订单数',
  'weekly.sales.table.total_online_paid_percent': '先款后货比例',
  'weekly.sales.table.total_online_paid_percent_calculation_note':
    '计算公式：先款后货订单数 / 订单数',
  'weekly.sales.table.total_cod': 'COD订单数',
  'weekly.sales.table.total_cod_explain_note':
    '说明：支付方式为cashondelivery的订单数',
  'weekly.sales.table.total_cod_percent': 'COD比例',
  'weekly.sales.table.total_cod_percent_calculation_note':
    '计算公式：COD订单数 / 订单数',
  'weekly.sales.table.total_cod_refund': 'COD退款订单数',
  'weekly.sales.table.total_cod_refund_explain_note':
    '说明：支付方式为cashondelivery的退款订单数',
  'weekly.sales.table.total_cod_refund_percent': 'COD退款率',
  'weekly.sales.table.total_cod_refund_percent_calculation_note':
    '计算公式：COD退款订单数 / COD订单数',
  'weekly.sales.table.phone.sales': '手机销量',
  'weekly.sales.table.phone_sales_amount': '销售金额$',
  'weekly.sales.table.phone_sales_amount_explain_note': '说明：手机类目获取',
  'weekly.sales.table.phone_sales_volume': '销量',
  'weekly.sales.table.phone_sales_volume_explain_note': '说明：手机类目获取',
  'weekly.sales.table.phone_sales_average': '销售均价$',
  'weekly.sales.table.phone_sales_average_calculation_note':
    '计算公式：销售金额 / 销量',
  'weekly.sales.table.product.supply': '产品供应',
  'weekly.sales.table.total_online_product': '总上架产品数',
  'weekly.sales.table.total_online_product_explain_note':
    '说明：至本周止，status为enable的产品数累加',
  'weekly.sales.table.new_online_product': '新增产品数',
  'weekly.sales.table.total_cross_border_product': '跨境商品数量',
  'weekly.sales.table.total_cross_border_product_percent': '跨境商品占比',
  'weekly.sales.table.total_cross_border_product_percent_calculation_note':
    '计算公式：跨境商品数量 / 总上架产品数',
  'weekly.sales.table.products_others_brand': '外牌商品数',
  'weekly.sales.table.products_others_brand_percent': '外牌商品数占比',
  'weekly.sales.table.products_others_brand_percent_calculation_note':
    '计算公式：外牌商品数 / 总上架产品数',
  'weekly.sales.table.active_product': '活跃产品数',
  'weekly.sales.table.active_product_explain_note': '说明：有销售的产品数',
  'weekly.sales.table.active_product_percent': '活跃比例%',
  'weekly.sales.table.active_product_percent_calculation_note':
    '计算公式：活跃产品数 / 总上架产品数',
  'weekly.sales.table.score': '店铺评分',
  'weekly.sales.table.ad.fb': '广告-FB',
  'weekly.sales.table.ad_fb_consumption': '实际消耗金额$',
  'weekly.sales.table.ad_fb_sales_amount': '直链销售金额$',
  'weekly.sales.table.ad_fb_roi': 'ROI',
  'weekly.sales.table.ad.google': '广告-Google',
  'weekly.sales.table.ad_google_consumption': '实际消耗金额$',
  'weekly.sales.table.ad_google_sales_amount': '直链销售金额$',
  'weekly.sales.table.ad_google_roi': 'ROI',
  'weekly.sales.table.ad_percentage': '广告占比',
  'weekly.sales.table.dm_total': 'DM总费用',
  'weekly.sales.table.dm_total_calculation_note':
    '计算公式：FB实际消耗金额 + Google实际消耗金额',
  'weekly.sales.table.total_promotion_consumption': '促销总费用',
  'weekly.sales.table.dm_gmv_percent': 'DM/GMV占比',
  'weekly.sales.table.dm_gmv_percent_calculation_note':
    '计算公式：DM总费用 / 成交金额GMV',
  'weekly.sales.table.ad_uv': '广告共引入UV',
  'weekly.sales.table.ad_uv_percent': '广告共引入UV占比',
  'weekly.sales.table.ad_uv_percent_calculation_note':
    '计算公式：广告共引入UV / 访客数UV',
  'weekly.dashboard.sales.table.dm_total':
    '计算公式：FB实际消耗金额 + Google实际消耗金额',
  'weekly.sales.table.gmv': '去年同期GMV',
  'weekly.sales.table.gmv_last_year': '去年同期成交金额GMV $',
  'weekly.sales.table.gmv_rate_year_on_year': '同比',
  'weekly.sales.table.gmv_rate_year_on_year_calculation_note':
    '计算公式：成交金额GMV / 去年同期成交金额 -1',
  'weekly.sales.table.uv_last': '去年同期UV',
  'weekly.sales.table.uv_last_year': '去年同期UV',
  'weekly.sales.table.uv_rate_year_on_year': '同比',
  'weekly.sales.table.uv_rate_year_on_year_calculation_note':
    '计算公式：访客数UV / 去年同期UV -1',
  'weekly.sales.table.gmv_phone': '去年同期手机GMV',
  'weekly.sales.table.gmv_phone_last_year': '去年同期销售金额',
  'weekly.sales.table.gmv_phone_rate_year_on_year': '同比',
  'weekly.sales.table.gmv_phone_rate_year_on_year_calculation_note':
    '计算公式：手机销售金额 / 去年手机销售金额 -1',
  'weekly.sales.table.total_order': '去年同期订单数',
  'weekly.sales.table.total_order_last_year': '去年同期',
  'weekly.sales.table.order_rate_year_on_year': '同比',
  'weekly.sales.table.order_rate_year_on_year_calculation_note':
    '计算公式：订单数 / 去年同期订单数 -1',
  'weekly.sales.table.price': '去年同期订单均价',
  'weekly.sales.table.price_last_year': '去年同期订单均价',
  'weekly.sales.table.price_last_year_calculation_note':
    '计算公式：去年同期成交金额GMV / 去年同期订单数',
  'weekly.sales.table.price_rate_year_on_year': '同比',
  'weekly.sales.table.price_rate_year_on_year_calculation_note':
    '计算公式：订单均价 / 去年同期订单均价 -1',
  'weekly.sales.table.conversion': '去年同期转化率',
  'weekly.sales.table.conversion_rate_last_year': '去年同期转化率',
  'weekly.sales.table.conversion_rate_last_year_calculation_note':
    '计算公式：去年同期订单数 / 去年同期UV',
  'weekly.sales.table.conversion_rate_year_on_year': '同比',
  'weekly.sales.table.conversion_rate_year_on_year_calculation_note':
    '计算公式：转化率 CVR / 去年同期转化率 -1',

  'monthly.sales.table.country': '国家',
  'monthly.sales.table.year': '年份',
  'monthly.sales.table.month': '月份',
  'monthly.sales.table.platform': '电商平台',
  'monthly.sales.table.sales_amount_wb': 'GMV外币',
  'monthly.sales.table.sales_amount': 'GMV(USD)',
  'monthly.sales.table.sales_amount_explain_note':
    '说明：由GMV外币经汇率转换得出',
  'monthly.sales.table.uv': 'UV',
  'monthly.sales.table.conversion_rate': '转化率',
  'monthly.sales.table.conversion_rate_calculation_note':
    '计算公式：订单数 / UV',
  'monthly.sales.table.sales_average': '订单均价(USD)',
  'monthly.sales.table.sales_average_calculation_note':
    '计算公式：GMV(USD) / 订单数',
  'monthly.sales.table.uv_worth': 'UV价值(USD)',
  'monthly.sales.table.uv_worth_calculation_note': '计算公式：GMV(USD) / UV',
  'monthly.sales.table.sales_volume': '订单数',
  'monthly.sales.table.total_online_paid': '先款后货订单数',
  'monthly.sales.table.total_online_paid_explain_note': '说明：线上支付订单数',
  'monthly.sales.table.total_online_paid_percent': '先款后货比例%',
  'monthly.sales.table.total_online_paid_percent_calculation_note':
    '计算公式：先款后货订单数 / 订单数',
  'monthly.sales.table.total_online_product': '总上架产品数',
  'monthly.sales.table.total_online_product_explain_note':
    '说明：至本月止，status为enable的产品数累加',
  'monthly.sales.table.active_product': '活跃产品数',
  'monthly.sales.table.active_product_explain_note': '说明：有销售的产品数',
  'monthly.sales.table.active_product_percent': '活跃比例%',
  'monthly.sales.table.active_product_percent_calculation_note':
    '计算公式：活跃产品数 / 总上架产品数',
  'monthly.sales.table.total_cross_border_product': '跨境产品数',
  'monthly.sales.table.total_cross_border_product_percent': '跨境比例%',
  'monthly.sales.table.total_cross_border_product_percent_calculation_note':
    '计算公式：跨境产品数 / 总上架产品数',
  'monthly.sales.table.promotion_consumption': '市场推广费用(USD)',
  'monthly.sales.table.promotion_consumption_percent': '市场推广费%',
  'monthly.sales.table.promotion_consumption_percent_calculation_note':
    '计算公式：市场推广费用(USD) / GMV(USD)',
  'monthly.sales.table.gmv_percent': '各国GMV占比',
  'monthly.sales.table.gmv_percent_explain_note':
    '说明：GMV(USD) 占全球GMV(USD) 比例',
  'monthly.sales.table.gmv_percent_calculation_note':
    '计算公式：GMV(USD)  / 本月全球GMV(USD)总和',

  EG: '埃及',
  NG: '尼日利亚',
  GH: '加纳',
  MA: '摩洛哥',
  PK: '巴基斯坦',
  KE: '肯尼亚',
  NGA: 'Tecno-尼日利亚',
  PAK: 'Tecno-巴基斯坦',
  GLOBAL: '线上全球',
  TECNO_GLOBAL: '全站',
  XPARK_GLOBAL: 'XPARK全球',
  All_Country: '所有国家',
  All_Platform: '所有平台',
  All_Pattern: '所有类型',

  // 周报新增字段
  'weekly.sales.table.month_sales_amount_gmv': '月GMV完成情况',
  'weekly.sales.table.month_sales_amount_completion_rate': '累计完成率',
  'weekly.sales.table.month_sales_amount_completion_rate_calculation_note':
    '计算公式：累计GMV / 月GMV目标',
  'weekly.sales.table.month_sales_amount_target': '月GMV目标',
  'weekly.sales.table.month_active_users': '月活跃用户数',
  'weekly.sales.table.crowd_management': '人群经营（以18个月为时间段持续更新）',
  'weekly.sales.table.phone_management': '人群经营（手机类目）',
  'weekly.sales.table.digital_peripherals_management':
    '人群经营（数码周边类目）',
  'weekly.sales.table.big_fashion_management': '人群经营（大时尚类目）',
  'weekly.sales.table.makeups_management': '人群经营（美妆类目）',
  'weekly.sales.table.sports_management': '人群经营（运动类目）',
  'weekly.sales.table.baby_toys_management': '人群经营（母婴玩具类目）',
  'weekly.sales.table.other_management': '人群经营（其他（家居家电）类目）',
  'weekly.sales.table.old_user_ratio': '老客占比',
  'weekly.sales.table.old_user_ratio_explain_note':
    '说明：7天内老客户在18个月内总用户数所占有比例',
  'weekly.sales.table.last_purchase_time': '最近购买时长',
  'weekly.sales.table.last_purchase_time_explain_note':
    '说明：老用户最近2单的时间间隔就是最近购买时长，所有老用户的时间长度取平均值',
  'weekly.sales.table.last_purchase_time_calculation_note':
    '计算公式：18个月老客最近2单时间间隔总和 / 18个月老客数',
  'weekly.sales.table.phone_last_purchase_time': '最近购买时长',
  'weekly.sales.table.phone_last_purchase_time_explain_note': '',
  'weekly.sales.table.phone_last_purchase_time_calculation_note': '',
  'weekly.sales.table.digital_peripherals_last_purchase_time': '最近购买时长',
  'weekly.sales.table.big_fashion_last_purchase_time': '最近购买时长',
  'weekly.sales.table.makeups_last_purchase_time': '最近购买时长',
  'weekly.sales.table.sports_last_purchase_time': '最近购买时长',
  'weekly.sales.table.baby_toys_last_purchase_time': '最近购买时长',
  'weekly.sales.table.other_last_purchase_time': '最近购买时长',
  'weekly.sales.table.consumption_frequency': '消费频率',
  'weekly.sales.table.consumption_frequency_explain_note':
    '说明：18个月内新客占比',
  'weekly.sales.table.consumption_frequency_calculation_note':
    '计算公式：18个月新客数 / 18个月总购买用户数',
  'weekly.sales.table.phone_consumption_frequency': '消费频率',
  'weekly.sales.table.phone_consumption_frequency_explain_note': '',
  'weekly.sales.table.phone_consumption_frequency_calculation_note': '',
  'weekly.sales.table.digital_peripherals_consumption_frequency': '消费频率',
  'weekly.sales.table.big_fashion_consumption_frequency': '消费频率',
  'weekly.sales.table.makeups_consumption_frequency': '消费频率',
  'weekly.sales.table.sports_consumption_frequency': '消费频率',
  'weekly.sales.table.baby_toys_consumption_frequency': '消费频率',
  'weekly.sales.table.other_consumption_frequency': '消费频率',
  'weekly.sales.table.purchase_amount': '购物金额',
  'weekly.sales.table.phone_purchase_amount': '购物金额',
  'weekly.sales.table.digital_peripherals_purchase_amount': '购物金额',
  'weekly.sales.table.big_fashion_purchase_amount': '购物金额',
  'weekly.sales.table.makeups_purchase_amount': '购物金额',
  'weekly.sales.table.sports_purchase_amount': '购物金额',
  'weekly.sales.table.baby_toys_purchase_amount': '购物金额',
  'weekly.sales.table.other_purchase_amount': '购物金额',
  'weekly.sales.table.purchase_amount_explain_note': '',
  'weekly.sales.table.purchase_amount_calculation_note': '',
  'weekly.sales.table.after_buying_rate': '复购率',
  'weekly.sales.table.after_buying_rate_explain_note':
    '说明：18个月内购买2次以上人数/18个月内总购买用户数',
  'weekly.sales.table.after_buying_rate_calculation_note':
    '计算公式：18个月内购买2次以上人数 / 18个月内总购买用户数',
  'weekly.sales.table.phone_after_buying_rate': '复购率',
  'weekly.sales.table.phone_after_buying_rate_explain_note': '',
  'weekly.sales.table.phone_after_buying_rate_calculation_note': '',
  'weekly.sales.table.digital_peripherals_after_buying_rate': '复购率',
  'weekly.sales.table.big_fashion_after_buying_rate': '复购率',
  'weekly.sales.table.makeups_after_buying_rate': '复购率',
  'weekly.sales.table.sports_after_buying_rate': '复购率',
  'weekly.sales.table.baby_toys_after_buying_rate': '复购率',
  'weekly.sales.table.other_after_buying_rate': '复购率',
  'weekly.sales.table.ratio_of_repurchase_cycle': '复购周期占比',
  'weekly.sales.table.ratio_of_repurchase_cycle_explain_note':
    '说明：8个月内购买2次用户数/18个月内总购买用户数',
  'weekly.sales.table.ratio_of_repurchase_cycle_calculation_note':
    '计算公式：8个月内购买2次以上人数 / 18个月内总购买用户数',
  'weekly.sales.table.phone_ratio_of_repurchase_cycle': '复购周期占比',
  'weekly.sales.table.phone_ratio_of_repurchase_cycle_explain_note': '',
  'weekly.sales.table.phone_ratio_of_repurchase_cycle_calculation_note': '',
  'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle':
    '复购周期占比',
  'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle': '复购周期占比',
  'weekly.sales.table.makeups_ratio_of_repurchase_cycle': '复购周期占比',
  'weekly.sales.table.sports_ratio_of_repurchase_cycle': '复购周期占比',
  'weekly.sales.table.baby_toys_ratio_of_repurchase_cycle': '复购周期占比',
  'weekly.sales.table.other_ratio_of_repurchase_cycle': '复购周期占比',
  'weekly.dashboard.sales.table.after_buying_rate': '复购率',
  'weekly.dashboard.sales.table.after_buying_rate_explain_note':
    '说明：18个月内老用户订单数占18个月内总订单数',
  'weekly.dashboard.sales.table.ratio_of_repurchase_cycle': '复购周期占比',
  'weekly.dashboard.sales.table.ratio_of_repurchase_cycle_explain_note':
    '说明：8个月内老用户数/总购买用户数',
  'weekly.dashboard.sales.table.ratio_of_repurchase_cycle_calculation_note':
    '计算公式：8个月老客数 / 8个月总购买用户数',
  'weekly.sales.table.joint_rate': '连带率',
  'weekly.sales.table.joint_rate_explain_note':
    '说明：成交商品件数/成交人数 完成订单即订单状态为complete、shipped',
  'weekly.sales.table.joint_rate_calculation_note':
    '计算公式：完成订单的产品总数 / 完成订单的总购买用户数',
  'weekly.sales.table.phone_joint_rate': '连带率',
  'weekly.sales.table.phone_joint_rate_explain_note': '',
  'weekly.sales.table.phone_joint_rate_calculation_note': '',
  'weekly.sales.table.digital_peripherals_joint_rate': '连带率',
  'weekly.sales.table.big_fashion_joint_rate': '连带率',
  'weekly.sales.table.makeups_joint_rate': '连带率',
  'weekly.sales.table.sports_joint_rate': '连带率',
  'weekly.sales.table.baby_toys_joint_rate': '连带率',
  'weekly.sales.table.other_joint_rate': '连带率',
  'weekly.sales.table.bad_review_rate': '差评率',
  'weekly.sales.table.bad_review_rate_explain_note':
    '说明：差评/总评价数 评星3星及以下为差评',
  'weekly.sales.table.bad_review_rate_calculation_note':
    '计算公式：18个月差评数 / 18个月总评价数',
  'weekly.sales.table.phone_bad_review_rate': '差评率',
  'weekly.sales.table.phone_bad_review_rate_explain_note': '',
  'weekly.sales.table.phone_bad_review_rate_calculation_note': '',
  'weekly.sales.table.digital_peripherals_bad_review_rate': '差评率',
  'weekly.sales.table.big_fashion_bad_review_rate': '差评率',
  'weekly.sales.table.makeups_bad_review_rate': '差评率',
  'weekly.sales.table.sports_bad_review_rate': '差评率',
  'weekly.sales.table.baby_toys_bad_review_rate': '差评率',
  'weekly.sales.table.other_bad_review_rate': '差评率',
  'weekly.sales.table.fb_score': 'FB评分',
  'weekly.sales.table.xpark.cumulative_register_totals': 'Xpark注册用户总数',
  'weekly.sales.table.cumulative_register_totals': '累计注册用户数',
  'weekly.sales.table.cumulative_register_totals_explain_note':
    '说明：至本周止，注册用户数累加',
  'weekly.sales.table.month.cumulative_register_totals_explain_note':
    '说明：至本月止，注册用户数累加',
  'weekly.sales.table.xpark_use_time': 'Xpark使用时长（秒）',
  'weekly.sales.table.app_activation_number': 'APP激活数',
  'weekly.sales.table.app_active_user_count': 'APP用户活跃数',
  'weekly.sales.table.current_week_preassem_count': '本周下载量',
  'weekly.sales.table.current_month_preassem_count': '本月下载量',
  'weekly.sales.table.private_pool_data': '私域流量池数据',
  'weekly.sales.table.cumulative_of_fans': '累计粉丝人数',
  'weekly.sales.table.new_of_fans': '本周新增粉丝数',
  'weekly.sales.table.cumulative_of_fan_base': '累计粉丝群数量',
  'weekly.sales.table.new_of_fan_base': '本周新建粉丝群数量',
  'weekly.sales.table.cumulative_of_portrait_short_video': '累计竖屏短视频数量',
  'weekly.sales.table.new_of_portrait_short_video': '累计新增竖屏短视频数量',
  'weekly.sales.table.new_media_data': '新社媒数据',
  'weekly.sales.table.cumulative_of_kol_cooperation': '累计KOL合作数量',
  'weekly.sales.table.new_of_kol_users': '本周KOL新增人数',
  'weekly.sales.table.cumulative_of_kol_orders': '累计KOL完成订单数',
  'weekly.sales.table.kol_orders': '本周KOL完成订单数',
  'weekly.sales.table.roi_new_social_media': '本周新社媒合作ROI',
  'weekly.sales.table.uv_new_social_media': '本周新社媒引流UV',
  'weekly.sales.table.delivery_data': '全名带货数据',
  'weekly.sales.table.total_xp_users': '累计XP总人数',
  'weekly.sales.table.total_xp_users_explain_note': '说明：累计网红用户总数',
  'weekly.sales.table.new_of_xp_users': '本周XP新增人数',
  'weekly.sales.table.new_of_xp_users_explain_note': '说明：本周网红新增人数',
  'weekly.sales.table.cumulative_of_xp_orders': '累计XP完成订单数',
  'weekly.sales.table.cumulative_of_xp_orders_explain_note':
    '说明：累计网红订单数（所有状态的订单）',
  'weekly.sales.table.complete_of_xp_orders': '本周XP完成订单数',
  'weekly.sales.table.complete_of_xp_orders_explain_note':
    '说明：本周网红订单数（所有状态的订单）',
  'weekly.sales.table.cumulative_of_complete_xp_orders':
    '累计XP在Xpark完成的订单数',
  'weekly.sales.table.cumulative_of_complete_xp_orders_explain_note':
    '说明：累计网红订单数（订单状态为完成的订单complete、shipped）',
  'weekly.sales.table.complete_of_xp_orders_in_xpark':
    '本周XP在Xpark完成的订单数',
  'weekly.sales.table.complete_of_xp_orders_in_xpark_explain_note':
    '说明：本周网红订单数（订单状态为完成的订单complete、shipped）',
  'weekly.sales.table.new_users_with_old_users': '本周老带新用户数',
  'weekly.sales.table.new_users_with_old_users_explain_note':
    '说明：本周新增粉丝数',
  'weekly.sales.table.pp_data': 'PP商数据',
  'weekly.sales.table.complete_of_pp_users': '累计PP商总数',
  'weekly.sales.table.new_of_pp_users': '本周PP商新增数',
  'weekly.sales.table.complete_of_pp_orders': '累计PP商完成订单数',
  'weekly.sales.table.complete_of_pp_orders_explain_note':
    '说明：累计PP商完成订单数（pp商订单状态为shipping）',
  'weekly.sales.table.pp_orders': '本周PP商完成订单数',
  'weekly.sales.table.pp_orders_explain_note':
    '说明：本周PP商完成订单数（pp商订单状态为shipping）',
  'weekly.sales.table.pp_orders_execution_rate': '本周履约成功率',
  'weekly.sales.table.pp_orders_execution_rate_explain_note':
    '说明：本周PP商完成订单数 / 订单数 (订单数为周报中“订单—订单数”)',
  'weekly.sales.table.pp_orders_execution_rate_calculation_note':
    '计算公式：本周PP商完成订单数 / 订单数',
  'weekly.sales.dashboard.table.total_orders': '订单数量',
  'weekly.sales.dashboard.table.total_non_transsion_percent': '外牌订单占比',
  'weekly.sales.dashboard.table.total_non_transsion_percent_calculation_note':
    '计算公式：非transsion品牌订单数 / 订单数',
  'weekly.sales.dashboard.table.ad_percentage': 'DM',
  'weekly.sales.dashboard.table.dm_gmv_percent': 'DM/GMV',
  'weekly.sales.dashboard.table.product.supply': '产品',
  'weekly.sales.dashboard.table.total_cross_border_product': '跨境产品数量',
  'weekly.sales.dashboard.table.total_cross_border_product_percent':
    '跨境产品占比',
  'weekly.sales.dashboard.table.products_others_brand_percent': '外牌SKU数占比',
  'weekly.sales.table.month_sales_amount_cumulative': '当月累计GMV',
  'weekly.sales.table.top': 'TOP数据',
  'weekly.sales.table.spu_top_1': 'TOP1',
  'weekly.sales.table.spu_top_2': 'TOP2',
  'weekly.sales.table.spu_top_3': 'TOP3',

  // 周-人群经营

  'weekly.sales.table.last_purchase_time_1':
    '最近0-8个月内购买1次及以上用户占比',
  'weekly.sales.table.last_purchase_time_2':
    '最近8-10个月内购买1次及以上用户占比',
  'weekly.sales.table.last_purchase_time_3':
    '最近10-18个内购买1次及以上用户占比',
  'weekly.sales.table.consumption_frequency_1': '购买1次用户占比',
  'weekly.sales.table.consumption_frequency_2': '购买2次用户占比',
  'weekly.sales.table.consumption_frequency_3': '购买3次及以上用户占比',
  'weekly.sales.table.purchase_amount_1': '70美元以下购买用户数占比',
  'weekly.sales.table.purchase_amount_2': '70-100美元之间购买用户数占比',
  'weekly.sales.table.purchase_amount_3': '100美元以上购买用户数占比',
  'weekly.sales.table.purchase_amount_4': '100-150美元之间购买用户数占比',
  'weekly.sales.table.purchase_amount_5': '150美元以上购买用户数占比',
  'weekly.sales.table.after_buying_rate_1':
    '18个月内购买2次及以上人数/18个月内总购买用户数',
  'weekly.sales.table.ratio_of_repurchase_cycle_1': '0-8个月的复购周期占比',
  'weekly.sales.table.ratio_of_repurchase_cycle_2': '8-10个月的复购周期占比',
  'weekly.sales.table.ratio_of_repurchase_cycle_3': '10-18个月的复购周期占比',
  'weekly.sales.table.joint_rate_1': '成交商品件数除以成交人数',
  'weekly.sales.table.bad_review_rate_1': '点击显示本周评论详情',

  'weekly.sales.table.phone_last_purchase_time_1':
    '最近0-8个月内购买1次及以上用户占比',
  'weekly.sales.table.phone_last_purchase_time_2':
    '最近8-10个月内购买1次及以上用户占比',
  'weekly.sales.table.phone_last_purchase_time_3':
    '最近10-18个内购买1次及以上用户占比',
  'weekly.sales.table.phone_consumption_frequency_1': '购买1次用户占比',
  'weekly.sales.table.phone_consumption_frequency_2': '购买2次用户占比',
  'weekly.sales.table.phone_consumption_frequency_3': '购买3次及以上用户占比',
  'weekly.sales.table.phone_purchase_amount_1': '70美元以下购买用户数占比',
  'weekly.sales.table.phone_purchase_amount_2': '70-100美元之间购买用户数占比',
  'weekly.sales.table.phone_purchase_amount_3': '100美元以上购买用户数占比',
  'weekly.sales.table.phone_purchase_amount_4': '100-150美元之间购买用户数占比',
  'weekly.sales.table.phone_purchase_amount_5': '150美元以上购买用户数占比',
  'weekly.sales.table.phone_after_buying_rate_1':
    '18个月内购买2次及以上人数/18个月内总购买用户数',
  'weekly.sales.table.phone_ratio_of_repurchase_cycle_1':
    '0-8个月的复购周期占比',
  'weekly.sales.table.phone_ratio_of_repurchase_cycle_2':
    '8-10个月的复购周期占比',
  'weekly.sales.table.phone_ratio_of_repurchase_cycle_3':
    '10-18个月的复购周期占比',
  'weekly.sales.table.phone_joint_rate_1': '成交商品件数除以成交人数',
  'weekly.sales.table.phone_bad_review_rate_1': '点击显示本周评论详情',

  'weekly.sales.table.digital_peripherals_last_purchase_time_1':
    '最近0-3个月内购买1次及以上用户占比',
  'weekly.sales.table.digital_peripherals_last_purchase_time_2':
    '最近3-6个月内购买1次及以上用户占比',
  'weekly.sales.table.digital_peripherals_last_purchase_time_3':
    '最近6-12个内购买1次及以上用户占比',
  'weekly.sales.table.digital_peripherals_consumption_frequency_1':
    '购买1次用户占比',
  'weekly.sales.table.digital_peripherals_consumption_frequency_2':
    '购买2次用户占比',
  'weekly.sales.table.digital_peripherals_consumption_frequency_3':
    '购买3次及以上用户占比',
  'weekly.sales.table.digital_peripherals_purchase_amount_1':
    '70美元以下购买用户数占比',
  'weekly.sales.table.digital_peripherals_purchase_amount_2':
    '70-100美元之间购买用户数占比',
  'weekly.sales.table.digital_peripherals_purchase_amount_3':
    '100美元以上购买用户数占比',
  'weekly.sales.table.digital_peripherals_purchase_amount_4':
    '100-150美元之间购买用户数占比',
  'weekly.sales.table.digital_peripherals_purchase_amount_5':
    '150美元以上购买用户数占比',
  'weekly.sales.table.digital_peripherals_after_buying_rate_1':
    '12个月内购买2次及以上人数/12个月内总购买用户数',
  'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_1':
    '0-3个月的复购周期占比',
  'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_2':
    '3-6个月的复购周期占比',
  'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_3':
    '6-12个月的复购周期占比',
  'weekly.sales.table.digital_peripherals_joint_rate_1':
    '成交商品件数除以成交人数',
  'weekly.sales.table.digital_peripherals_bad_review_rate_1':
    '点击显示本周评论详情',

  'weekly.sales.table.big_fashion_last_purchase_time_1':
    '最近0-1个月内购买1次及以上用户占比',
  'weekly.sales.table.big_fashion_last_purchase_time_2':
    '最近1-2个月内购买1次及以上用户占比',
  'weekly.sales.table.big_fashion_last_purchase_time_3':
    '最近2-12个内购买1次及以上用户占比',
  'weekly.sales.table.big_fashion_consumption_frequency_1': '购买1次用户占比',
  'weekly.sales.table.big_fashion_consumption_frequency_2': '购买2次用户占比',
  'weekly.sales.table.big_fashion_consumption_frequency_3':
    '购买3次及以上用户占比',
  'weekly.sales.table.big_fashion_purchase_amount_1':
    '70美元以下购买用户数占比',
  'weekly.sales.table.big_fashion_purchase_amount_2':
    '70-100美元之间购买用户数占比',
  'weekly.sales.table.big_fashion_purchase_amount_3':
    '100美元以上购买用户数占比',
  'weekly.sales.table.big_fashion_purchase_amount_4':
    '100-150美元之间购买用户数占比',
  'weekly.sales.table.big_fashion_purchase_amount_5':
    '150美元以上购买用户数占比',
  'weekly.sales.table.big_fashion_after_buying_rate_1':
    '12个月内购买2次及以上人数/12个月内总购买用户数',
  'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1':
    '0-1个月的复购周期占比',
  'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2':
    '1-2个月的复购周期占比',
  'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3':
    '2-12个月的复购周期占比',
  'weekly.sales.table.big_fashion_joint_rate_1': '成交商品件数除以成交人数',
  'weekly.sales.table.big_fashion_bad_review_rate_1': '点击显示本周评论详情',
  'weekly.sales.table.makeups_last_purchase_time_1':
    '最近0-1个月内购买1次及以上用户占比',
  'weekly.sales.table.makeups_last_purchase_time_2':
    '最近1-2个月内购买1次及以上用户占比',
  'weekly.sales.table.makeups_last_purchase_time_3':
    '最近2-12个内购买1次及以上用户占比',
  'weekly.sales.table.makeups_consumption_frequency_1': '购买1次用户占比',
  'weekly.sales.table.makeups_consumption_frequency_2': '购买2次用户占比',
  'weekly.sales.table.makeups_consumption_frequency_3': '购买3次及以上用户占比',
  'weekly.sales.table.makeups_purchase_amount_1': '70美元以下购买用户数占比',
  'weekly.sales.table.makeups_purchase_amount_2':
    '70-100美元之间购买用户数占比',
  'weekly.sales.table.makeups_purchase_amount_3': '100美元以上购买用户数占比',
  'weekly.sales.table.makeups_purchase_amount_4':
    '100-150美元之间购买用户数占比',
  'weekly.sales.table.makeups_purchase_amount_5': '150美元以上购买用户数占比',
  'weekly.sales.table.makeups_after_buying_rate_1':
    '12个月内购买2次及以上人数/12个月内总购买用户数',
  'weekly.sales.table.makeups_ratio_of_repurchase_cycle_1':
    '0-1个月的复购周期占比',
  'weekly.sales.table.makeups_ratio_of_repurchase_cycle_2':
    '1-2个月的复购周期占比',
  'weekly.sales.table.makeups_ratio_of_repurchase_cycle_3':
    '2-12个月的复购周期占比',
  'weekly.sales.table.makeups_joint_rate_1': '成交商品件数除以成交人数',
  'weekly.sales.table.makeups_bad_review_rate_1': '点击显示本周评论详情',

  'weekly.sales.table.other_consumption_frequency_1': '购买1次用户占比',
  'weekly.sales.table.other_consumption_frequency_2': '购买2次用户占比',
  'weekly.sales.table.other_consumption_frequency_3': '购买3次及以上用户占比',

  // 周-人群经营 备注

  'weekly.sales.table.calculation_note': '计算公式：',
  'weekly.sales.table.purchase_amount_note': '（10美元=订单中美妆的总金额）',
  'weekly.sales.table.last_purchase_time_calculation_note_1':
    '最近0-8个月内购买1次及以上用户占比=最近0-8个月内购买1次及以上用户数/18个月内总购买用户数',
  'weekly.sales.table.last_purchase_time_calculation_note_2':
    '最近8-10个月内购买1次及以上用户占比=最近8-10个月内购买1次及以上用户数/18个月内总购买用户数',
  'weekly.sales.table.last_purchase_time_calculation_note_3':
    '最近10-18个月内购买1次及以上用户占比=最近10-18个月内购买1次及以上用户数/18个月内总购买用户数',
  'weekly.sales.table.consumption_frequency_calculation_note_1':
    '购买1次用户占比=购买1次用户/18个月内购买用户总数',
  'weekly.sales.table.consumption_frequency_calculation_note_2':
    '购买2次用户占比=购买2次用户/18个月内购买用户总数',
  'weekly.sales.table.consumption_frequency_calculation_note_3':
    '购买3次及以上用户占比=购买3次及以上用户/18个月内购买用户总数',
  'weekly.sales.table.purchase_amount_calculation_note_1':
    '70美元以下购买用户数占比=70美元以下购买用户数/18个月内购买用户总数',
  'weekly.sales.table.purchase_amount_calculation_note_2':
    '70-100美元之间购买用户数占比=70-100美元之间购买用户数/18个月内购买用户总数',
  'weekly.sales.table.purchase_amount_calculation_note_3':
    '100美元以上购买用户数占比=100美元以上购买用户数/18个月内购买用户总数',
  'weekly.sales.table.purchase_amount_calculation_note_4':
    '100-150美元以上购买用户数占比=100-150美元以上购买用户数/18个月内购买用户总数',
  'weekly.sales.table.purchase_amount_calculation_note_5':
    '150美元以上购买用户数占比=150美元以上购买用户数/18个月内购买用户总数',
  'weekly.sales.table.after_buying_rate_calculation_note_1':
    '复购率=18个月内购买2次及以上人数/18个月内总购买用户数',
  'weekly.sales.table.ratio_of_repurchase_cycle_calculation_note_1':
    '0-8个月的复购周期占比=0-8个月内购买2次及以上用户数/18个月内总购买用户数',
  'weekly.sales.table.ratio_of_repurchase_cycle_calculation_note_2':
    '8-10个月的复购周期占比=8-10个月内购买2次及以上用户数/18个月内总购买用户数',
  'weekly.sales.table.ratio_of_repurchase_cycle_calculation_note_3':
    '10-18个月的复购周期占比=10-18个月内购买2次及以上用户数/18个月内总购买用户数',
  'weekly.sales.table.joint_rate_calculation_note_1':
    '客件数=成交商品件数除以成交人数',
  'weekly.sales.table.bad_review_rate_calculation_note_1':
    '差评率=本周差评数/本周总评论数',
  'weekly.sales.table.bad_review_rate_calculation_note_2':
    '差评率=本周差评数/本周总评论数',

  'weekly.sales.table.digital_peripherals_last_purchase_time_calculation_note_1':
    '最近0-3个月内购买1次及以上用户占比=最近0-3个月内购买1次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.digital_peripherals_last_purchase_time_calculation_note_2':
    '最近3-6个月内购买1次及以上用户占比=最近3-6个月内购买1次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.digital_peripherals_last_purchase_time_calculation_note_3':
    '最近6-12个月内购买1次及以上用户占比=最近6-12个月内购买1次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.digital_peripherals_consumption_frequency_calculation_note_1':
    '购买1次用户占比=购买1次用户/12个月内购买用户总数',
  'weekly.sales.table.digital_peripherals_consumption_frequency_calculation_note_2':
    '购买2次用户占比=购买2次用户/12个月内购买用户总数',
  'weekly.sales.table.digital_peripherals_consumption_frequency_calculation_note_3':
    '购买3次及以上用户占比=购买3次及以上用户/12个月内购买用户总数',
  'weekly.sales.table.digital_peripherals_purchase_amount_calculation_note_1':
    '70美元以下购买用户数占比=70美元以下购买用户数/12个月内购买用户总数',
  'weekly.sales.table.digital_peripherals_purchase_amount_calculation_note_2':
    '70-100美元之间购买用户数占比=70-100美元之间购买用户数/12个月内购买用户总数',
  'weekly.sales.table.digital_peripherals_purchase_amount_calculation_note_3':
    '100美元以上购买用户数占比=100美元以上购买用户数/12个月内购买用户总数',
  'weekly.sales.table.digital_peripherals_after_buying_rate_calculation_note_1':
    '复购率=12个月内购买2次及以上人数/12个月内总购买用户数',
  'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_calculation_note_1':
    '0-3个月的复购周期占比=0-3个月内购买2次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_calculation_note_2':
    '3-6个月的复购周期占比=3-6个月内购买2次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_calculation_note_3':
    '6-12个月的复购周期占比=6-12个月内购买2次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.digital_peripherals_joint_rate_calculation_note_1':
    '客件数=成交商品件数除以成交人数',
  'weekly.sales.table.digital_peripherals_bad_review_rate_calculation_note_1':
    '差评率=18个月差评数/18个月总评论数',

  'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_1':
    '最近0-1个月内购买1次及以上用户占比=最近0-1个月内购买1次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_2':
    '最近1-2个月内购买1次及以上用户占比=最近1-2个月内购买1次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_3':
    '最近2-12个月内购买1次及以上用户占比=最近2-12个月内购买1次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_1':
    '购买1次用户占比=购买1次用户/12个月内购买用户总数',
  'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_2':
    '购买2次用户占比=购买2次用户/12个月内购买用户总数',
  'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_3':
    '购买3次及以上用户占比=购买3次及以上用户/12个月内购买用户总数',
  'weekly.sales.table.big_fashion_purchase_amount_calculation_note_1':
    '70美元以下购买用户数占比=70美元以下购买用户数/12个月内购买用户总数',
  'weekly.sales.table.big_fashion_purchase_amount_calculation_note_2':
    '70-100美元之间购买用户数占比=70-100美元之间购买用户数/12个月内购买用户总数',
  'weekly.sales.table.big_fashion_purchase_amount_calculation_note_3':
    '100美元以上购买用户数占比=100美元以上购买用户数/12个月内购买用户总数',
  'weekly.sales.table.big_fashion_after_buying_rate_calculation_note_1':
    '复购率=12个月内购买2次及以上人数/12个月内总购买用户数',
  'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_1':
    '0-1个月的复购周期占比=0-1个月内购买2次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_2':
    '1-2个月的复购周期占比=1-2个月内购买2次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_3':
    '2-12个月的复购周期占比=2-12个月内购买2次及以上用户数/12个月内总购买用户数',
  'weekly.sales.table.big_fashion_joint_rate_calculation_note_1':
    '客件数=成交商品件数除以成交人数',
  'weekly.sales.table.big_fashion_bad_review_rate_calculation_note_1':
    '差评率=18个月差评数/18个月总评论数',

  // outer dashboard
  'dashboard.daily.table.country': '国家',
  'dashboard.daily.table.sales_amount': 'GMV',
  'dashboard.daily.table.orders': '订单数量',
  'dashboard.daily.table.uv': 'UV',
  'dashboard.daily.table.conversion_rate': 'CVR',
  'dashboard.daily.table.customer_order_average': '客单价',
  'dashboard.daily.table.online_paid_orders_percent': '先款后货比例',
  'dashboard.daily.table.total_online_product': '总上架产品数',
  'dashboard.daily.table.active_product_percent': '活跃产品比例',
  'dashboard.daily.table.phone_sales_volume': '手机销售量',
  'dashboard.daily.table.advertising_amount': '广告投放金额',
  'dashboard.daily.table.roi': 'ROI',
  'dashboard.daily.table.conversion_rate_explain_note': '说明：转化率',
  'dashboard.daily.table.conversion_rate_calculation_note':
    '计算公式：订单数量 / UV',
  'dashboard.daily.table.customer_order_average_calculation_note':
    '计算公式：订单金额 / 订单数',
  'dashboard.daily.table.online_paid_orders_percent_calculation_note':
    '计算公式：先款后货订单数 / 订单数',
  'dashboard.daily.table.total_online_product_explain_note':
    '说明：至本日止，status为enable的产品数累加',
  'dashboard.daily.table.active_product_percent_calculation_note':
    '计算公式：活跃产品数 / 总上架产品数',
  'dashboard.daily.table.phone_sales_volume_explain_note': '说明：手机类目获取',
  'dashboard.daily.table.advertising_amount_calculation_note':
    '计算公式：FB实际消耗金额$ + Google实际消耗金额$',
  'dashboard.daily.table.roi_calculation_note':
    '计算公式：广告投放金额 / GMV(两天前)',
  'dashboard.daily.table.bad_review_total': '差评数',
  'dashboard.daily.table.average_price': '均价',
  'dashboard.daily.table.spu_top_1': 'TOP1',
  'dashboard.daily.table.spu_top_2': 'TOP2',
  'dashboard.daily.table.spu_top_3': 'TOP3',

  // 曲线图
  'echarts.total': '可视报表',
  'echarts.gmv-trend': '各国GMV趋势图',
  'echarts.gmv-trend.week-trend': '各国GMV趋势图(周)',
  'echarts.gmv-trend.month-trend': '各国GMV趋势图(月)',
  'echarts.gmv-trend.month-catalog-gmv': '各国各类目GMV趋势图(月)',
  'echarts.gmv-trend.month-catalog-sales': '各国各类目销量趋势图(月)',
  'echarts.gmv-pie': '各国GMV饼图',
  'echarts.gmv-pie.week-pie': '各国GMV饼图(周)',
  'echarts.gmv-pie.month-pie': '各国GMV饼图(月)',
  'echarts.gmv-pie.month-catalog-pie': '各国各类目GMV饼图(月)',
  'echarts.gmv-pie.month-catalog-pie-sales': '各国各类目销量饼图(月)',
  'echarts.stores': 'infinix和外牌',
  'echarts.stores.week-stores': 'infinix和外牌(周)',
  'echarts.stores.month-stores': 'infinix和外牌(月)',
  'echarts.stores.month-stores-pie': 'infinix和外牌饼图(月)',
  'echarts.preassem-active': '装机、活跃用户',
  'echarts.preassem-active.week-active': '装机、活跃用户趋势图(周)',
  'echarts.preassem-active.month-active': '装机、活跃用户趋势图(月)',
  'echarts.cross': '跨境电商',
  'echarts.cross-trend.month-total-gmv': '跨境电商趋势图(月)',
  'echarts.cross-ng-gmv': '跨境电商GMV(仅NG)',
  'echarts.cross-ng-gmv.month-total-gmv': '跨境电商GMV(仅NG)(月)',
  'echarts.cross-ng-sku': '跨境电商SKU(仅NG)',
  'echarts.cross-ng-sku.month-total-sku': '跨境电商SKU(仅NG)(月)',
  'echarts.use-time': '留存率及使用时长',
  'echarts.use-time.week-use-time': '留存率及使用时长(周)',
  'echarts.use-time.month-use-time': '留存率及使用时长(月)',
  'echarts.year': '年报表',
  'echarts.year-flow': '年流量报表',
  'echarts.year-manage': '年经营报表',

  // 模型命令
  'show-command.model': '决策模型类型',
  'show-command.model.step': '步骤',
  'show-command.model.diff': '区分',
  'show-command.sql': 'SQL语句',

  // 控盘
  'app.sider.total-report': '控盘',
  'app.sider.total-report.total': '总盘',
  'app.sider.total-report.site': '站点盘',
  'total.report.table.month': '月份',
  'total.report.table.country': '国家',
  'total.report.table.budget': '预算',
  'total.report.table.actual': '实际',
  'total.report.table.completion_rate': '完成率',
  'total.report.table.last_actual': '去年',
  'total.report.table.rate_year_on_year': '同比',
  'total.report.table.uv': 'UV',
  'total.report.table.app': 'APP',
  'total.report.table.gmv': 'GMV',
  'total.report.table.income': '收入',
  'total.report.table.cost': '成本',
  'total.report.table.profit': '毛利',
  'total.report.table.outlay': '费用',
  'total.report.table.market_outlay': '市场费用',
  'total.report.table.net_profit': '净利润',
  'review.title': '标题',
  'review.nickname': '名字',
  'review.rating': '评分',
  'review.detail': '内容',
  'review.ppshop': 'pp商',
  'bad.review.detail': '评论详情（评分满分为5分）',
  'app.sider.model-command': 'SQL语句',
  'app.sider.model-command.show-command': '决策模型数据生成SQL语句',

  'monthly.sales.table.app_activation_number': '本月APP激活数',
  'monthly.sales.table.app_active_user_count': 'APP用户活跃数',
  'monthly.sales.table.accumu_preassem_count': '累计下载量',
  'monthly.sales.table.current_month_preassem_count': '本月下载量',
  'monthly.sales.table.download_amount': '本月下载量',
  'monthly.sales.table.month_active_users': 'xpark活跃用户数',
  'monthly.sales.table.all_active_users': 'ga+app活跃用户数',
  'monthly.sales.table.app_active_users': 'APP月活跃用户数',
  'monthly.sales.table.new_of_fans': '本月新增粉丝数',
  'monthly.sales.table.new_of_fan_base': '本月新建粉丝群数量',
  'monthly.sales.table.new_of_kol_users': '本月KOL新增人数',
  'monthly.sales.table.kol_orders': '本月KOL完成订单数',
  'monthly.sales.table.roi_new_social_media': '本月新社媒合作ROI',
  'monthly.sales.table.uv_new_social_media': '本月新社媒引流UV',
  'monthly.sales.table.new_of_xp_users': '本月XP新增人数',
  'monthly.sales.table.complete_of_xp_orders': '本月XP完成订单数',
  'monthly.sales.table.complete_of_xp_orders_in_xpark':
    '本月XP在Xpark完成的订单数',
  'monthly.sales.table.new_users_with_old_users': '本月老带新用户数',
  'monthly.sales.table.new_of_pp_users': '本月PP商新增数',
  'monthly.sales.table.pp_orders': '本月PP商完成订单数',
  'monthly.sales.table.pp_orders_execution_rate': '本月履约成功率',
  'monthly.sales.table.pp_orders_execution_rate_explain_note':
    '说明：本月PP商完成订单数 / 订单数 (订单数为周报中“订单—订单数”)',
  'monthly.sales.table.pp_orders_execution_rate_calculation_note':
    '计算公式：本月PP商完成订单数 / 订单数',
  'monthly.sales.table.pp_orders_explain_note':
    '说明：本月PP商完成订单数（pp商订单状态为shipping）',
  'monthly.sales.table.complete_of_xp_orders_in_xpark_explain_note':
    '说明：本月网红订单数（订单状态为完成的订单complete、shipped）',
  'monthly.sales.table.complete_of_xp_orders_explain_note':
    '说明：本月网红订单数（所有状态的订单）',
  'monthly.sales.table.new_of_xp_users_explain_note': '说明：本月网红新增人数',
  'monthly.sales.table.xpark_use_time': '本月Xpark使用时长（秒）',
  'monthly.sales.table.new_users_with_old_users_explain_note':
    '说明：本月新增粉丝数',
  'monthly.sales.table.bad_review_rate_calculation_note':
    '差评率=本月差评数/本月总评论数',
  'monthly.sales.table.bad_review_rate': '点击显示本月评论详情',
  'daily.data.fill.fields': '未填写数据字段',

  // Settings
  'app.sider.settings': '设置',
  'app.sider.schema.settings': '系统设置',
  'app.sider.promotion.settings': '促销设置',
  submit: '提交',

  // promotion settings
  id: 'ID',
  country: '国家',
  platform: '平台',
  name: '名称',
  description: '描述',
  start_date: '开始日期',
  end_date: '结束日期',
  created_at: '创建日期',
  promotion_type: '类型',

  pp_orders_cancel_rate: '订单取消率',
  pp_orders_dealt_rate: '妥投率',
  pp_orders_return_rate: '退单率',
  pp_orders_phone_rate: '订单电话确认率',
  pp_orders_shiped_rate: '36小时内成功发货订单率',
  pp_orders_ship_delay_days: '平均发货天数',

  'big-query.device_type': '活动名称',
  'big-query.start_time': '开始时间',
  'big-query.end_time': '结束时间',
  'big-query.user_count': '用户数量',
  'big-query.click_count': '点击数量',
  'big-query.pattern': '决策模型',
  'big-query.Affinity_name': '关联名称',
  'big-query.name': '名称',
  'top-audience': '顶级受众',
  'potential-audience': '潜在受众',
  audience: '观众',
  'big-query.score': '分数',
  'big-query.ctr': 'ctr',
  'big-query.period': 'period',
  'big-query.budget': 'budget',
  'big-query.percentage': 'percentage',
  drainage: '引流评分',
  combination: '组合评分',
  'app-user-mistakes': 'N/A',
  distribution: '预算分配',
  effect: '投放效果'
}
