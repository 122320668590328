import request from './request'

// crowd funding
export const getActivityDetail = (params) =>
  request.get('/backend/crowd-founding/activity/detail', { params })

export const getActivityList = (params) =>
  request.get('/backend/crowd-founding/activity/get-list', { params })

export const saveActivity = ({ id, data }) => {
  return request.post(`/backend/crowd-founding/activity/save?id=${id}`, {
    data
  })
}

export const createActivity = ({ country, data }) =>
  request.post(
    `/backend/crowd-founding/activity/create?___country=${country}`,
    { data }
  )

export const deleteActivity = ({ id, data }) =>
  request.post(`/backend/crowd-founding/activity/delete?id=${id}`, {
    data
  })

export const getOrderList = (params) =>
  request.get('/backend/crowd-founding/order/get-list', { params })

// rewrite
export const getRewriteList = (params) =>
  request.get('/backend/external-system/rewrite/get-list', { params })

export const getRewriteDetail = (params) =>
  request.get('/backend/external-system/rewrite/detail', { params })

export const updateRewrite = (params) => {
  return request.post('/backend/external-system/rewrite/edit', params)
}

export const createRewrite = (params) =>
  request.post('/backend/external-system/rewrite/create', params)

export const deleteRewrite = (params) =>
  request.post('/backend/external-system/rewrite/delete', params)
