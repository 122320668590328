import React, { useEffect, useState, useContext } from 'react'
import { Form, Row, Col, Input } from 'antd'
import { connect } from 'react-redux'
import {
  GridFormDrawer,
  SearchSelect,
  GridContext,
  TranslatableForm,
  Uploader
} from '../../../../../../components'

import {
  getActivityItemDetail,
  createActivityItem,
  updateActivityItem
} from '../../services'
import services from '../../../../../../services'
import TypeSelect from './TypeSelect'

interface Props {
  onChange: () => {};
}

const toReadable = (odds) => {
  const f = Math.round(odds * 1000000) / 100
  if (f === 0) {
    return 0
  }
  if (f > 100) {
    return `${f / 100}%`
  }
  if (f > 10) {
    return `${f / 10}‰`
  }
  return `${f}‱`
}

const ActivityItemForm = ({ onChange, currentCountry }: Props) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const openHandler = getActivityItemDetail(setFormData)
  const { toggle, record } = useContext(GridContext)
  const [oddsMsg, setOddsMsg] = useState(toReadable(formData.odds))

  const closeDrawerWithChange = () => {
    toggle({}, '')
    onChange()
  }
  const emptyForm = () => {
    setFormData({})
    form.resetFields()
  }
  const onFinish = (values) => {
    if (formData.id) {
      updateActivityItem(closeDrawerWithChange)({
        data: { ...values, activity_id: record.activity_id },
        id: formData.id
      })
    } else {
      createActivityItem(closeDrawerWithChange)({
        data: { ...values, activity_id: record.activity_id }
      })
    }
  }

  const handleFormChange = (changedFields) => {
    changedFields.forEach((e) => {
      if (e.name.indexOf('odds') !== -1) {
        setOddsMsg(toReadable(e.value))
      }
    })
  }

  useEffect(() => {
    if (Object.keys(formData).length) {
      form.setFieldsValue(formData)
    }
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      width="75%"
      type="edit_item|create_item"
      title={(record) => (record.id ? '新建奖项' : '编辑奖项')}
    >
      <TranslatableForm entity="luckyspin_activity_item">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={onFinish}
          onFieldsChange={handleFormChange}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="item_title"
                label="奖项名称"
                rules={[{ required: true, message: 'Please enter title' }]}
              >
                <Input placeholder="Please enter title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="image"
                label="图片"
                rules={[{ required: true, message: 'Please enter title' }]}
              >
                <Uploader />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="item_type"
                label="奖品类型"
                rules={[{ required: true, message: 'Please enter title' }]}
              >
                <TypeSelect
                  onChange={(v) => {
                    setFormData({ ...formData, item_type: v })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {formData.item_type === 'coupon' && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="target_id"
                  label="优惠券规则"
                  rules={[{ required: true, message: 'Please enter rule id' }]}
                >
                  <SearchSelect
                    showSearch
                    country={currentCountry}
                    fetchApi={services.luckyspin.getActivityRules}
                    idField="rule_id"
                    labelRender={(item) => item.name}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="odds"
                label="中奖机率"
                rules={[
                  {
                    required: true,
                    message: 'Please enter odds'
                  },
                  {
                    validator: (_, val) => {
                      if (val > 1 || val < 0.000001) {
                        return Promise.reject(new Error('请输入正确的机率'))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input placeholder="Please enter odds" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <span>{oddsMsg}</span>
            </Col>
          </Row>
          {formData.item_type !== 'thankyou' && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="total"
                  label="奖品总数"
                  rules={[{ required: true, message: 'Please enter total' }]}
                >
                  <Input placeholder="Please enter total" />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="description" label="描述">
                <Input.TextArea row={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </TranslatableForm>
    </GridFormDrawer>
  )
}

const mapStateToProps = ({ global }) => ({
  currentCountry: global.current_country
})
export default connect(mapStateToProps)(ActivityItemForm)
