import React, { useEffect, useState, useContext } from 'react'
import { Form, Row, Col, Input } from 'antd'
import { GridFormDrawer, GridContext } from '../../../../components'
import { getRoleDetail, updateRole, createRole } from '../services'
import PermissionChecker from './PermissionChecker'
import ScopeSelect from './ScopeSelect'

const RoleForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const [checkedPermissions, setCheckedPermissions] = useState([])
  const openHandler = getRoleDetail(setFormData)
  const { onLoad, toggle, record } = useContext(GridContext)
  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }
  const emptyForm = () => {
    setFormData({})
    form.resetFields()
  }
  const onFinish = (values) => {
    let permissions = record.permissions ? record.permissions.split(',') : []
    if (checkedPermissions.length) {
      permissions = checkedPermissions
    }
    if (formData.id) {
      updateRole(closeDrawer)({
        data: { ...values, permissions },
        id: formData.id
      })
    } else {
      createRole(closeDrawer)({ data: { ...values, permissions } })
    }
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title="Edit Form"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="role_name"
              label="Role name"
              rules={[{ required: true, message: 'Please enter role name' }]}
            >
              <Input placeholder="Please enter role name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="role_alias" label="Alias">
              <Input placeholder="Alias" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="remark" label="Remark">
              <Input placeholder="remark" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="scopes" label="Scopes">
              <ScopeSelect />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={4} />
          <Col span={12}>
            <PermissionChecker
              onChange={setCheckedPermissions}
              permissions={formData.permissions}
            />
          </Col>
        </Row>
      </Form>
    </GridFormDrawer>
  )
}
export default RoleForm
