import React from 'react'
import { Typography, Row, Col } from 'antd'

import { getStaticText } from '../../utils'

const { Title, Text } = Typography

const GlobalTips = () => {
  return (
    <>
      <Title level={5}>{getStaticText('app.tips')}</Title>
      <div
        className="floatf"
        style={{
          width: '400px'
        }}
      >
        <Row>
          <Col>
            <Text type="warning">
              1. 每个国家日、周、月报表因为时区原因，有一定差异
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text type="warning">2. 周，按照周天-下周六进行计算 </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text type="warning">
              3. 翻年的周，按照后一年w1中计算。也是计算7天的数据
            </Text>
          </Col>
        </Row>
      </div>
      <div
        className="floatf"
        style={{
          width: '70px'
        }}
      >
        <Row>
          <Text type="warning">4. 汇率</Text>
        </Row>
      </div>
      <div
        className="floatf"
        style={{
          width: '280px'
        }}
      >
        <Row>
          <Text type="warning">埃及 1(USD) = 15.6492(EGP)</Text>
        </Row>
        <Row>
          <Text type="warning">加纳 1(USD) = 5.82271(GHS)</Text>
        </Row>
        <Row>
          <Text type="warning">尼日利亚 1(USD) = 380.471(NGN)</Text>
        </Row>
      </div>
      <div
        className="floatf"
        style={{
          width: '280px'
        }}
      >
        <Row>
          <Text type="warning">巴基斯坦 1(USD) = 158.226(PKR)</Text>
        </Row>
        <Row>
          <Text type="warning">摩洛哥 1(USD) = 9.16835(MAD)</Text>
        </Row>
        <Row>
          <Text type="warning">肯尼亚 1(USD) = 108.852(KES)</Text>
        </Row>
      </div>
    </>
  )
}

export default GlobalTips
