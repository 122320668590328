import {
  MonthlyDashboard,
  WeeklyDashboard,
  DailyDashboard,
  PeopleYard
} from '../pages'

const report = {
  path: '/report',
  primary: 'app.sider.report',
  routes: [
    {
      path: '/daily',
      primary: 'app.sider.daily.sales',
      routes: [
        {
          path: '/report/daily/dashboard',
          permission: 'daily-sales/dashboard',
          modular: 'report',
          component: DailyDashboard,
          primary: 'app.sider.daily.sales.dashboard',
          exact: true
        }
      ]
    },
    {
      path: '/weekly',
      primary: 'app.sider.weekly.sales',
      routes: [
        {
          path: '/report/weekly/dashboard',
          permission: 'weekly-sales/dashboard',
          modular: 'report',
          component: WeeklyDashboard,
          primary: 'app.sider.weekly.sales.dashboard',
          exact: true
        }
        // {
        //   path: '/report/weekly/manage',
        //   component: Management,
        //   primary: 'app.sider.weekly.management',
        //   exact: true
        // }
      ]
    },
    {
      path: '/monthly',
      component: MonthlyDashboard,
      primary: 'app.sider.monthly.sales',
      exact: true,
      routes: [
        {
          path: '/report/monthly/dashboard',
          permission: 'monthly-sales/dashboard',
          modular: 'report',
          component: MonthlyDashboard,
          primary: 'app.sider.monthly.sales.dashboard',
          exact: true
        },
        {
          path: '/report/monthly/people-yard',
          permission: 'comprehensive/dashboard',
          modular: 'report',
          primary: 'app.sider.people-yard',
          component: PeopleYard,
          exact: true
        }
      ]
    }
  ]
}
export default report
