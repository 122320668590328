import React from 'react'
import Actions from './Actions'

export const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    align: 'center'
  },
  {
    title: '国家',
    dataIndex: 'country',
    key: 'country',
    width: 100,
    align: 'center'
  },
  /* {
    title: '是否要登录',
    dataIndex: 'allow_guest',
    key: 'allow_guest',
    width: 100,
    align: 'center',
    render: (v) => {
      return v.allow_guest ? '是' : '否'
    }
  }, */
  {
    title: '主题',
    dataIndex: 'theme',
    key: 'theme',
    align: 'center',
    render: (theme) => {
      return theme ? (
        <img height="50px" alt={theme.name} src={theme.picture} />
      ) : (
        '--'
      )
    }
  },
  {
    title: '链接',
    dataIndex: 'link',
    key: 'link',
    align: 'center',
    render: (link) => {
      return (
        <a href={link} target="_blank" rel="noreferrer">
          查看
        </a>
      )
    }
  },
  {
    title: '活动开始时间',
    dataIndex: 'start_time',
    key: 'start_time',
    align: 'center'
  },
  {
    title: '活动结束时间',
    dataIndex: 'end_time',
    key: 'end_time',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
  {
    title: '修改时间',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: '',
    width: 140,
    key: 'x',
    fixed: 'right',
    align: 'center',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
