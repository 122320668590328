import request from './request'

export const getWeeklySales = (data) =>
  request.post('/backend/weekly-sales/get-list', data)

export const editWeeklySales = (data) =>
  request.post('/backend/weekly-sales/edit', data)

export const exportWeeklySales = (params) =>
  request.get('/backend/weekly-sales/export', { params })

export const exportWeeklySalesTemplate = () =>
  request.get('/backend/weekly-sales/export-template')

export const exportDashboard = (params) => {
  return request.get('/backend/weekly-sales/export-dashboard', { params })
}
