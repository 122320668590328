import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Form, Row, Col, Input, DatePicker } from 'antd'
import moment from 'moment'

import ThemeSelect from './ThemeSelect'
import {
  GridFormDrawer,
  CountrySelect,
  GridContext,
  TranslatableForm
} from '../../../../../components'

import { getActivityDetail, createActivity, updateActivity } from '../services'

const dateFormat = 'YYYY-MM-DD hh:mm:ss'

const ActivityForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const { onLoad, toggle } = useContext(GridContext)
  const [country, setCountry] = useState('')
  const [theme, setTheme] = useState({})
  const emptyForm = () => {
    setFormData({})
    form.resetFields()
  }

  const closeDrawer = () => {
    toggle({}, '')
    emptyForm()
    onLoad()
  }

  const countryChange = (value) => {
    setCountry(value)
  }

  const formatResData = useCallback((value) => {
    const newValue = Object.assign(value, {
      country: value.country,
      title: value.title,
      chances: value.chances,
      start_time: moment(value.start_time, dateFormat),
      end_time: moment(value.end_time, dateFormat),
      allowed_guest: !!Number(value.allow_guest),
      short_description: value.short_description,
      description: value.description,
      url_key: value.url_key
    })
    setCountry(newValue.country)
    setFormData(newValue)
  }, [])

  const openHandler = (id) => {
    getActivityDetail(formatResData)(id)
  }

  const onFinish = (values) => {
    const formatValue = {
      country: values.country,
      title: values.title,
      allow_guest: values.allowed_guest ? '1' : '0',
      chances: values.chances,
      start_time: values.start_time.format(dateFormat),
      end_time: values.end_time.format(dateFormat),
      short_description: values.short_description,
      description: values.description,
      url_key: values.url_key
    }
    if (formData.id) {
      updateActivity(closeDrawer)({ data: formatValue, id: formData.id })
    } else {
      createActivity(closeDrawer)({ country, data: formatValue })
    }
  }

  useEffect(() => {
    if (Object.keys(formData).length) {
      form.setFieldsValue(formData)
    }
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Activity' : 'New Activity')}
    >
      <TranslatableForm entity="luckyspin_activity">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="country" name="country">
                <CountrySelect onChange={countryChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please enter title' }]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder="Please enter title"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="theme"
                label="Theme"
                rules={[{ required: true, message: 'Please select theme' }]}
              >
                <ThemeSelect onThemeChange={setTheme} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginBottom: '10px' }}>
            <Col span={4} />
            <Col span={12}>
              {theme.picture && (
                <img height="200px" src={theme.picture} alt={theme.name} />
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="chances" label="Chances">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="start_time"
                label="Start time"
                rules={[{ required: true, message: 'Please enter start time' }]}
              >
                <DatePicker showTime />
              </Form.Item>
              <Form.Item
                name="end_time"
                label="End time"
                rules={[{ required: true, message: 'Please enter end time' }]}
              >
                <DatePicker showTime />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="short_description" label="Short Description">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="description" label="Description">
                <Input.TextArea row={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="url_key" label="Url_key">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </TranslatableForm>
    </GridFormDrawer>
  )
}
export default ActivityForm
