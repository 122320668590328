import React from 'react'
import Actions from './Actions'
import { getTypeLabel } from '../Edit/TypeSelect'

export const createColumns = ({ onChange }) => [
  {
    title: '图片',
    key: 'image',
    align: 'center',
    render: (i) => {
      return i.img_url ? (
        <img height="50px" alt={i.item_title} src={i.img_url} />
      ) : (
        '--'
      )
    }
  },
  {
    title: '奖项',
    dataIndex: 'item_title',
    key: 'item_title',
    align: 'center'
  },
  {
    title: '总数量',
    dataIndex: 'total',
    key: 'item_total',
    align: 'center'
  },
  {
    title: '剩余数量',
    key: 'item_total',
    align: 'center',
    render: (data) => data.total - data.consumed
  },
  {
    title: '奖品类型',
    dataIndex: 'item_type',
    key: 'item_type',
    align: 'center',
    render: (v) => getTypeLabel(v)
  },
  {
    title: '描述',
    dataIndex: 'description',
    key: 'description',
    align: 'center'
  },
  {
    title: '中奖机率',
    dataIndex: 'odds',
    key: 'odds',
    align: 'center',
    render: (odds) => {
      const f = Math.round(odds * 1000000) / 100
      if (f === 0) {
        return 0
      }
      if (f > 100) {
        return `${f / 10000}  (${f / 100}%)`
      }
      if (f > 10) {
        return `${f / 10000}  (${f / 10}‰)`
      }
      return `${f / 10000}  (${f}‱)`
    }
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
  {
    title: '更新时间',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center'
  },
  {
    title: '操作',
    width: 200,
    key: 'x',
    align: 'center',
    render: (record) => <Actions record={record} onChange={onChange} />
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
