import React, { Component } from 'react'
import { Button, Form, Input, Select, Space, InputNumber } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const { Option } = Select
class ModelForm2 extends Component {
  render() {
    if (this.props.type === 'affinity') {
      return (
        <div>
          <Form.Item name="country" label="国家" rules={[{ required: true }]}>
            <Select placeholder="Select a country" allowClear>
              <Option value="EG">埃及</Option>
              <Option value="NG">尼日利亚</Option>
              <Option value="KE">肯尼亚</Option>
              <Option value="MA">摩洛哥</Option>
              <Option value="PK">巴基斯坦</Option>
              <Option value="GH">加纳</Option>
              <Option value="NGA">Tecno-尼日利亚</Option>
              <Option value="PAK">Tecno-巴基斯坦</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="campaign_name"
            label="活动名称"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
      )
    }
    if (this.props.type === 'creative') {
      return (
        <div>
          <Form.Item name="country" label="国家" rules={[{ required: true }]}>
            <Select placeholder="Select a country" allowClear>
              <Option value="EG">埃及</Option>
              <Option value="NG">尼日利亚</Option>
              <Option value="KE">肯尼亚</Option>
              <Option value="MA">摩洛哥</Option>
              <Option value="PK">巴基斯坦</Option>
              <Option value="GH">加纳</Option>
              <Option value="NGA">Tecno-尼日利亚</Option>
              <Option value="PAK">Tecno-巴基斯坦</Option>
            </Select>
          </Form.Item>
          <Form.List
            name="campaign_id"
            label="输入可以作为指标的 campaign"
            rules={[{ required: true }]}
            initialValue={[
              {
                fieldKey: 0,
                isListField: true,
                key: 0,
                name: 0
              }
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'campaign_id']}
                      label="Campaign Id"
                      fieldKey={[fieldKey, 'campaign_id']}
                      rules={[
                        { required: true, message: 'Missing campaign id' }
                      ]}
                    >
                      <InputNumber placeholder="campaign id" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Campaign Id(at least a data)
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.List
            name="ad"
            label="输入所有的 YTB AD ID"
            rules={[{ required: true }]}
            initialValue={[
              {
                fieldKey: 0,
                isListField: true,
                key: 0,
                name: 0
              }
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'first']}
                      label="First Creative Id"
                      fieldKey={[fieldKey, 'first']}
                      rules={[
                        { required: true, message: 'Missing first creative id' }
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'second']}
                      label="Second Creative Id"
                      fieldKey={[fieldKey, 'second']}
                      rules={[
                        { required: true, message: 'Missing last creative id' }
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'creative_name']}
                      label="Creative Name"
                      fieldKey={[fieldKey, 'creative_name']}
                      rules={[
                        { required: true, message: 'Missing creative name' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add YTB AD ID(at least a data)
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            name="create_group"
            label="分组数字1"
            rules={[{ required: true }]}
          >
            <Input placeholder="格式：x,x,x....x" />
          </Form.Item>
          <Form.Item
            name="select_group"
            label="分组数字2"
            rules={[{ required: true }]}
          >
            <Input placeholder="格式：x,x,x....x" />
          </Form.Item>
        </div>
      )
    }
    if (this.props.type === 'campaign') {
      return (
        <div>
          <Form.Item name="country" label="国家" rules={[{ required: true }]}>
            <Select placeholder="Select a country" allowClear>
              <Option value="EG">埃及</Option>
              <Option value="NG">尼日利亚</Option>
              <Option value="KE">肯尼亚</Option>
              <Option value="MA">摩洛哥</Option>
              <Option value="PK">巴基斯坦</Option>
              <Option value="GH">加纳</Option>
              <Option value="NGA">Tecno-尼日利亚</Option>
              <Option value="PAK">Tecno-巴基斯坦</Option>
            </Select>
          </Form.Item>
          <Form.List
            name="campaign_id"
            label="输入可以作为指标的 campaign"
            rules={[{ required: true }]}
            initialValue={[
              {
                fieldKey: 0,
                isListField: true,
                key: 0,
                name: 0
              }
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'campaign_id']}
                      label="Campaign Id"
                      fieldKey={[fieldKey, 'campaign_id']}
                      rules={[
                        { required: true, message: 'Missing campaign id' }
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Campaign id(at least a data)
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.List
            name="campaign"
            label="输入所有的 YTB campaign ID"
            rules={[{ required: true }]}
            initialValue={[
              {
                fieldKey: 0,
                isListField: true,
                key: 0,
                name: 0
              }
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, 'first']}
                      label="Campaign Id"
                      fieldKey={[fieldKey, 'campaign']}
                      rules={[
                        { required: true, message: 'Missing campaign id' }
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'campaign_name']}
                      label="Campaign Name"
                      fieldKey={[fieldKey, 'campaign_name']}
                      rules={[
                        { required: true, message: 'Missing campaign_name' }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add YTB campaign ID(at least a data)
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      )
    }
  }
}
ModelForm2.propTypes = {
  type: PropTypes.string.isRequired
}
export default ModelForm2
