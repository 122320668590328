import { Creatings } from '../pages'

const share = {
  path: '/share',
  primary: 'app.sider.share',
  routes: [
    {
      path: '/share/cut',
      modular: 'market',
      permission: 'share-cut',
      primary: 'app.sider.share.cut',
      component: Creatings,
      exact: true
    },
    {
      path: '/share/group-buy',
      modular: 'market',
      permission: 'share-group-buy',
      primary: 'app.sider.share.group-buy',
      component: Creatings,
      exact: true
    }
  ]
}
export default share
