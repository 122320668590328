import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { Select, Spin, Pagination } from 'antd'
import debounce from 'lodash/debounce'

interface DebounceProps {
  debounceTimeout: Number;
  mode: String;
  showSearch: Boolean;
  value: String;
  placeholder: String;
  onChange: Function;
  country: String;
  fetchApi: Function;
  labelRender: () => {};
}

const SearchSelect = ({
  debounceTimeout = 800,
  mode,
  showSearch,
  value,
  placeholder,
  onChange,
  country,
  fetchApi,
  idField,
  labelRender
}: DebounceProps) => {
  idField = idField || 'id'
  labelRender = labelRender || ((i) => i.name || i.title)
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)

  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    ___country: '',
    page: 1,
    scenario: 'product/dropdown-search',
    page_size: 10,
    search: ''
  })

  const fetchDataList = useCallback(
    (name, defaltParams = { ...params }) => {
      return fetchApi({ ...defaltParams, search: name }).then((res) => {
        if (res.data) {
          setTotal(res.total)
          return res.data.map((item) => ({
            label: labelRender(item),
            value: item[idField]
          }))
        }
      })
    },
    [fetchApi, params, idField, labelRender]
  )

  const handlePagination = useCallback(
    (current) => {
      setParams((preValue) => {
        return { ...preValue, page: current }
      })
      setOptions([])
      setFetching(true)
      fetchDataList(params.search, { ...params, page: current }).then((res) => {
        setOptions(res)
        setFetching(false)
      })
    },
    [fetchDataList, params]
  )

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      setParams((preValue) => {
        return { ...preValue, search: value, page: 1 }
      })
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchDataList(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        setOptions(newOptions)
        setFetching(false)
      })
    }
    return debounce(loadOptions, debounceTimeout)
  }, [fetchDataList, debounceTimeout])

  const addPagination = useCallback(
    (options) => {
      return (
        <div>
          {options}
          <Pagination
            style={{ float: 'right' }}
            simple
            showQuickJumper={false}
            defaultPageSize={10}
            current={params.page}
            total={total}
            onChange={handlePagination}
          />
        </div>
      )
    },
    [params, handlePagination, total]
  )

  const handleFocus = useCallback(() => {
    setFetching(true)
    setParams((preValue) => {
      return { ...preValue, search: '', page: 1 }
    })
    fetchDataList('').then((res) => {
      setOptions(res)
      setFetching(false)
    })
  }, [fetchDataList])

  useEffect(() => {
    setOptions([])
    setParams({ ...params, ___country: country })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  return (
    <Select
      style={{
        width: '100%'
      }}
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      onFocus={handleFocus}
      dropdownRender={addPagination}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      mode={mode}
      showSearch={showSearch}
      value={value}
      showArrow
      disabled={!country}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      onMouseLeave={() => {}}
    />
  )
}

export default SearchSelect
