import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getUserList = (handler) =>
  withTableLoading((params) => services.rbac.getUserList(params).then(handler))

export const getUserDetail = (handler) =>
  withGlobalLoading((id) => services.rbac.getUserDetail({ id }).then(handler))

export const deleteUser = (handler) =>
  withGlobalLoading((id) => services.rbac.deleteUser({ id }).then(handler))

export const createUser = (handler) =>
  withGlobalLoading((params) => services.rbac.createUser(params).then(handler))

export const updateUser = (handler) =>
  withGlobalLoading((params) => services.rbac.saveUser(params).then(handler))

export const getRoleList = (handler) => (params) =>
  services.rbac.getRoleList(params).then(handler)
