import React from 'react'
import Actions from './Actions'

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: 'Banner Name',
    dataIndex: 'banner_name',
    key: 'banner_name',
    align: 'center',
    width: 100
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center'
  },
  {
    title: 'Target Url',
    dataIndex: 'target_url',
    key: 'target_url',
    align: 'center'
  },
  {
    title: 'action',
    dataIndex: '',
    width: 200,
    key: 'x',
    align: 'center',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 15,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
