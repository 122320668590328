import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import {
  Table,
  Grid,
  Modal,
  Typography,
  Row,
  Col,
  Divider,
  Empty,
  DatePicker
} from 'antd'
import moment from 'moment'

import services from '../../services'
import logo from '../../assets/logo-1.png'
import { DashboardDailyColumns } from '../../config/tableColumns'
import { getStaticText } from '../../utils'
import dateFormat from '../../config/dateFormat'

const { useBreakpoint } = Grid
const { Text } = Typography

// 日格式化标准
const dateFormatDay = dateFormat.day

const DashboardDaily = () => {
  const screens = useBreakpoint()
  // 默认今天减1
  const defaultDay = moment().subtract(1, 'day')

  const currentBreakPoint = useMemo(() => {
    return Object.keys(screens).filter((screen) => screens[screen])
  }, [screens])

  const tableSize = useCallback(() => {
    let size = 'default'
    if (currentBreakPoint.includes('xs')) {
      size = 'small'
    } else if (!currentBreakPoint.includes('xl', 'xxl')) {
      size = 'middle'
    }
    return size
  }, [currentBreakPoint])

  const [isLoading, setIsLoading] = useState(false)

  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  const [requestParams, setRequestParams] = useState(() => {
    return {
      report_date: defaultDay,
      format: defaultDay.format(dateFormatDay)
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      report_date: requestParams.format
    }
  }, [requestParams])

  const [resultData, setResultData] = useState({
    week: '',
    report_date: '',
    daily_normal_dashboard: []
  })

  const fetchData = useCallback(() => {
    toggleLoading()
    services.dashboardDaily
      .getDashboardDaily(requestParamsTransformer)
      .then((result) => {
        setResultData((prevState) => ({ ...prevState, ...result }))
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  const timerId = useRef()

  const IntermittentRequest = useCallback(() => {
    fetchData()
    timerId.current = setTimeout(IntermittentRequest, 30000)
  }, [fetchData])

  // 增加Review

  const [reviewsData, setReviewsData] = useState([])
  const [modalVisible, setModalDataVisible] = useState(false)
  const toggleModal = () => {
    setModalDataVisible((prevState) => {
      if (prevState === true) {
        setReviewsData([])
      }
      return !prevState
    })
  }

  const requestReviewParams = useCallback((row, resultData) => {
    return {
      country: row.country,
      type: 1, // type: 1 代表日报，type: 2 代表周报
      date: resultData.report_date,
      current: 1,
      pageSize: 20
    }
  }, [])

  const setModalData = useCallback(
    (row, resultData) => {
      const param = requestReviewParams(row, resultData)
      services.common.getBadReviewDetail(param).then((result) => {
        setReviewsData(result.data.data.review_details || [])
      })
      toggleModal()
    },
    [requestReviewParams]
  )

  const newColumns = useMemo(() => {
    const reviewColumn = DashboardDailyColumns.filter(
      (column) => column.key === 'bad_review_total'
    )

    if (reviewColumn && reviewColumn.length) {
      reviewColumn[0].render = (value, row) => {
        return (
          <span
            className="clickLabel"
            onClick={() => setModalData(row, resultData)}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            {value}
          </span>
        )
      }
    }

    return DashboardDailyColumns
  }, [setModalData, resultData])

  useEffect(() => {
    fetchData()
    timerId.current = setTimeout(IntermittentRequest, 3000000)
    return () => {
      clearTimeout(timerId.current)
    }
  }, [fetchData, IntermittentRequest])

  const handleChangeDate = useCallback((date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      report_date: date,
      format: dateString
    }))
  }, [])

  return (
    <div className="dashboard dashboard-daily">
      <div className="dashboard-container">
        <header className="dashboard-header">
          <div className="dashboard__title">
            <img className="dashboard__logo" src={logo} alt="" />
          </div>
          <div className="dashboard-time">
            <div className="dashboard-time__item">
              <span className="dashboard-time__label">Date</span>
              <span className="dashboard-time__value">
                {/* {resultData.report_date} */}
                <DatePicker
                  defaultValue={defaultDay}
                  size="large"
                  format={dateFormatDay}
                  onChange={handleChangeDate}
                />
              </span>
            </div>
            <div className="dashboard-time__item">
              <span className="dashboard-time__label">Week</span>
              <span className="dashboard-time__value">{`第${resultData.week}周`}</span>
            </div>
          </div>
        </header>
        <section className="dashboard-content">
          <Table
            className="table--dark"
            size={tableSize()}
            loading={isLoading}
            columns={newColumns}
            dataSource={resultData.daily_normal_dashboard}
            rowKey={(record) => record.id}
            tableLayout="fixed"
            bordered={false}
            pagination={false}
          />
        </section>

        <Modal
          title={getStaticText('bad.review.detail')}
          visible={modalVisible}
          footer={null}
          onCancel={toggleModal}
        >
          {reviewsData.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            reviewsData.map((review) => {
              return (
                <div key={review.review_id}>
                  <Row>
                    <Col span={24}>
                      <Text>
                        {getStaticText('review.title')}
                        <span>：</span>
                        {review.title}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        {getStaticText('review.nickname')}
                        <span>：</span>
                        {review.nickname}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        {getStaticText('review.rating')}
                        <span>：</span>
                        {review.rating}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        {getStaticText('review.detail')}
                        <span>：</span>
                        {review.detail}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        {getStaticText('review.ppshop')}
                        <span>：</span>
                        {review.pp_user.toString()}
                      </Text>
                    </Col>
                  </Row>
                  <Divider />
                </div>
              )
            })
          )}
        </Modal>
      </div>
    </div>
  )
}

export default DashboardDaily
