import request from './request'

export const getDailySales = (data) =>
  request.post('/backend/daily-sales/get-list', data)

export const editDailySales = (data) =>
  request.post('/backend/daily-sales/edit', data)

export const exportDailySales = (params) =>
  request.get('/backend/daily-sales/export', { params })

export const exportDailySalesTemplate = () =>
  request.get('/backend/daily-sales/export-template')

export const getDashboard = (data) =>
  request.post('/backend/daily-sales/dashboard', data)

export const exportDashboard = (params) =>
  request.get('/backend/daily-sales/export-dashboard', { params })
