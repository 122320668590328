import { Creatings } from '../pages'

const recharge = {
  path: '/recharge',
  primary: 'app.sider.recharge',
  modular: 'market',
  permission: 'recharge',
  component: Creatings,
  exact: true
}
export default recharge
