import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Space, Card, Typography, Button, DatePicker } from 'antd'
import { getStaticText } from '../../../utils'
import services from '../../../services'
import dateFormat from '../../../config/dateFormat'

const dateFormatMonth = dateFormat.month
const { Text } = Typography
const { RangePicker } = DatePicker

const requestParams = {
  start_date: moment().subtract('months', 12),
  end_date: moment().subtract('months', 1)
}

const requestOptionPreass = {
  grid: {
    bottom: 40,
    top: 40,
    left: 40,
    containLabel: true
  },
  toolbox: {
    show: true,
    feature: {
      dataView: {
        readOnly: false,
        title: '编辑'
      },
      restore: {
        show: true,
        title: '重置'
      },
      saveAsImage: {
        show: true,
        title: '下载'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    data: []
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      name: ''
    }
  ],
  series: []
}

const getbarLabel = {
  show: true,
  position: 'top',
  formatter: (value) =>
    value.data.toString().length > 4
      ? `${parseInt(value.data / 10000, 10) + 1}万`
      : value.data
}

const getLineLabel = {
  show: true,
  position: 'top',
  formatter: (value) => {
    return `${value.data}%`
  }
}

const requestOptionActive = {
  ...requestOptionPreass,
  legend: {
    data: []
  }
}

const requestOptionActive2 = {
  ...requestOptionPreass,
  legend: {
    data: []
  }
}

const requestOptionActive3 = {
  ...requestOptionPreass,
  legend: {
    data: []
  }
}

class MonthActive extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatMonth)
      : requestParams.start_date
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatMonth)
      : requestParams.end_date
    this.state = {
      start_date: startDate,
      start_format: startDate.format(dateFormatMonth),
      end_date: endDate,
      end_format: endDate.format(dateFormatMonth)
    }
    this.useEffect()
  }

  getrequest = () => {
    return {
      start_date: this.state.start_format,
      end_date: this.state.end_format
    }
  }

  handleReset = () => {
    this.setState(
      {
        start_date: requestParams.start_date,
        start_format: requestParams.start_date.format(dateFormatMonth),
        end_date: requestParams.end_date,
        end_format: requestParams.end_date.format(dateFormatMonth)
      },
      () => {
        this.useEffect()
      }
    )
  }

  handleDateChange = (date, dateString) => {
    this.setState(
      {
        start_date: moment(dateString[0], dateFormatMonth),
        start_format: dateString[0],
        end_date: moment(dateString[1], dateFormatMonth),
        end_format: dateString[1]
      },
      () => {
        this.useEffect()
      }
    )
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchGmvData()
  }

  fetchGmvData = () => {
    services.mapList.monthActive(this.getrequest()).then((result) => {
      if (result.series1 instanceof Array) {
        result.series1 = result.series1.map((value) => {
          if (value.type === 'bar') {
            value.label = getbarLabel
          } else if (value.type === 'line') {
            value.label = getLineLabel
            value.yAxisIndex = 1
          }
          return value
        })
      }
      if (result.series2 instanceof Array) {
        result.series2 = result.series2.map((value) => {
          if (value.type === 'bar') {
            value.label = getbarLabel
          } else if (value.type === 'line') {
            value.label = getLineLabel
            value.yAxisIndex = 1
          }
          return value
        })
      }
      if (result.series3 instanceof Array) {
        result.series3 = result.series3.map((value) => {
          if (value.type === 'bar') {
            value.label = getbarLabel
          } else if (value.type === 'line') {
            value.label = getLineLabel
            value.yAxisIndex = 1
          }
          return value
        })
      }
      if (result.series4 instanceof Array) {
        result.series4 = result.series4.map((value) => {
          if (value.type === 'bar') {
            value.label = getbarLabel
          } else if (value.type === 'line') {
            value.label = getLineLabel
            value.yAxisIndex = 1
          }
          return value
        })
      }
      requestOptionPreass.xAxis[0].data = result.month_list
      requestOptionPreass.series = result.series1
      requestOptionPreass.legend.data = result.legend1
      requestOptionActive.xAxis[0].data = result.month_list
      requestOptionActive.series = result.series2
      requestOptionActive.legend.data = result.legend2
      requestOptionActive2.xAxis[0].data = result.month_list
      requestOptionActive2.series = result.series3
      requestOptionActive2.legend.data = result.legend3
      requestOptionActive3.xAxis[0].data = result.month_list
      requestOptionActive3.series = result.series4
      requestOptionActive3.legend.data = result.legend4
      this.echartsElement.getEchartsInstance().clear() // 清空画布
      this.echartsElement2.getEchartsInstance().clear() // 清空画布
      this.echartsElement3.getEchartsInstance().clear() // 清空画布
      // this.echartsElement4.getEchartsInstance().clear() // 清空画布
      this.echartsElement.getEchartsInstance().setOption(requestOptionPreass) // 实时改变
      this.echartsElement2.getEchartsInstance().setOption(requestOptionActive) // 实时改变
      this.echartsElement3.getEchartsInstance().setOption(requestOptionActive2) // 实时改变
      // this.echartsElement4.getEchartsInstance().setOption(requestOptionActive3) // 实时改变
    })
  }

  render() {
    return (
      <>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <RangePicker
              picker="month"
              value={[this.state.start_date, this.state.end_date]}
              format={dateFormatMonth}
              onChange={this.handleDateChange}
              allowClear={false}
            />
          </Space>
          <Space style={{ float: 'right' }}>
            <Button type="primary" onClick={this.handleReset}>
              {getStaticText('component.search.button.reset')}
            </Button>
          </Space>
        </Card>
        <ReactEcharts
          ref={(e) => {
            this.echartsElement = e
          }}
          style={{ width: '100%' }}
          option={requestOptionPreass}
        />
        <ReactEcharts
          ref={(e) => {
            this.echartsElement2 = e
          }}
          style={{ width: '100%' }}
          option={requestOptionActive}
        />
        <ReactEcharts
          ref={(e) => {
            this.echartsElement3 = e
          }}
          style={{ width: '100%' }}
          option={requestOptionActive}
        />
      </>
    )
  }
}

MonthActive.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default MonthActive
