import * as dailySales from './dailySales'
import * as weeklySales from './weeklySales'
import * as monthlySales from './monthlySales'
import * as users from './users'
import * as weeklySalesDashboard from './weeklySalesDashboard'
import * as dashboardDaily from './dashboard/daily'
import * as totalReport from './totalReport'
import * as siteTotalReport from './siteTotalReport'
import * as common from './common'
import * as review from './review'
import * as dailyDataFill from './dailyDataFill'
import * as peopleYard from './peopleYard'
import * as settings from './settings'
import * as bigQuery from './bigQuery'
import * as rbac from './rbac'
import * as luckyspin from './luckyspin'
import * as mapList from './mapList'
import * as bbs from './bbs'
import * as system from './system'
import * as jump from './jump'

export * from './pagination'
export * from './loadings'
export * from './translation'

export default {
  dailySales,
  weeklySales,
  monthlySales,
  users,
  weeklySalesDashboard,
  dashboardDaily,
  totalReport,
  siteTotalReport,
  common,
  review,
  dailyDataFill,
  peopleYard,
  settings,
  bigQuery,
  rbac,
  luckyspin,
  mapList,
  bbs,
  system,
  jump
}
