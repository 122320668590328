import React from 'react'
import { Typography, Space, Input } from 'antd'
import { getStaticText } from '../../../../utils'

const { Text } = Typography

interface Props {
  name: String;
}

const TextFilter = ({ name, value, onChange }: Props) => {
  const changeHandler = (e) => {
    onChange(e.target.value)
  }
  return (
    <>
      <Space>
        <Text>{getStaticText('component.search')}</Text>
        <Input
          style={{ width: '200px' }}
          value={value}
          onChange={changeHandler}
          placeholder={name}
        />
      </Space>
    </>
  )
}

export default TextFilter
