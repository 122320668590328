import React, { useState, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import {
  GridFormDrawer,
  GridContext,
  PagingTable
} from '../../../../components'
import { createColumns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getReviewList } from '../services'

interface Props {
  onLoad: Function;
}

const ReviewList = ({ onLoad }: Props) => {
  const location = useLocation()

  const [gridData, setGridData] = useState(initGridData)
  const { record } = useContext(GridContext)

  const filterCountry = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('___country')
  }, [location])

  const onOpen = (record) => {
    getReviewList(setGridData)({ id: record.id, ___country: filterCountry })
  }
  return (
    <>
      <GridFormDrawer type="review" title="Reviews" onOpen={onOpen}>
        <PagingTable
          columns={createColumns({
            onChange: () => {
              onOpen(record)
              onLoad()
            }
          })}
          dataSource={dataSourceMap(gridData.data)}
          total={gridData.total}
          initGridData={initGridData}
          current={gridData.page}
          pageSize={gridData.page_size}
        />
      </GridFormDrawer>
    </>
  )
}

export default ReviewList
