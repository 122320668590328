import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function Crm(props) {
  const { tableData } = props
  return (
    <>
      {/* CRM */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="3" width="100" className="sticky-left sticky-second">
          CRM
        </th>
        <th colSpan="3">
          新客数量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  当期新用户数，以邮箱作为唯一标准，再加上注册用户。
                  <br />
                  期间内新注册用户数+（期间内下单的新邮箱数）
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          老客数量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>这个期间之前所有老用户数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">新客成交GMV</th>
        <th colSpan="3">老客成交GMV</th>
        <th colSpan="3">会员总数量</th>
        <th colSpan="3">新增会员数量</th>
        <th colSpan="3">
          入会率 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>入会会员人数/平台访客数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          活跃会员数 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>收藏，加购，下单，领券，互动</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">活跃会员占总会员数%</th>
        <th colSpan="3">成交会员数</th>
        <th colSpan="3">会员成交客单价</th>
        <th colSpan="3">会员成交GMV</th>
        <th colSpan="3">会员成交GMV占总GMV%</th>
      </tr>

      {/* row2 */}
      <tr>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：新客数量/去年同期新客数量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：老客数量/去年同期老客数量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：新客成交GMV/去年同期新客成交GMV-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：老客成交GMV/去年同期老客成交GMV1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：会员总人数/去年同期会员总人数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：本期新增会员人数/去年同期新增会员人数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：新增会员数量/游客</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：入会率/去年同期入会率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：活跃会员数量/去年同期活跃会员数量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：活跃会员数/会员总数量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：活跃会员占总会员数/去年同期活跃会员占总会员数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：成交会员数/去年同期成交会员数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际成交会员GMV/成交会员数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：会员成交客单价/去年同期会员成交客单价-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：会员成交GMV/去年同期会员成交GMV-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：会员成交GMV/总GMV</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：会员成交GMV占总GMV/去年同期会员成交GMV占总GMV-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.new_customers_actual}</td>
        <td>{tableData.new_customers_budget}</td>
        <td>{tableData.new_customers_rate_year_on_year}</td>
        <td>{tableData.old_customers_actual}</td>
        <td>{tableData.old_customers_budget}</td>
        <td>{tableData.old_customers_rate_year_on_year}</td>
        <td>{tableData.new_customers_gmv_actual}</td>
        <td>{tableData.new_customers_gmv_budget}</td>
        <td>{tableData.new_customers_gmv_rate_year_on_year}</td>
        <td>{tableData.old_customers_gmv_actual}</td>
        <td>{tableData.old_customers_gmv_budget}</td>
        <td>{tableData.old_customers_gmv_rate_year_on_year}</td>
        <td>{tableData.cumulative_register_totals_actual}</td>
        <td>{tableData.cumulative_register_totals_budget}</td>
        <td>{tableData.cumulative_register_totals_rate_year_on_year}</td>
        <td>{tableData.new_register_totals_actual}</td>
        <td>{tableData.new_register_totals_budget}</td>
        <td>{tableData.new_register_totals_rate_year_on_year}</td>
        <td>{tableData.paticipation_rate_actual}</td>
        <td>{tableData.paticipation_rate_budget}</td>
        <td>{tableData.paticipation_rate_rate_year_on_year}</td>
        <td>{tableData.active_users_actual}</td>
        <td>{tableData.active_users_budget}</td>
        <td>{tableData.active_users_rate_year_on_year}</td>
        <td>{tableData.active_user_rate_actual}</td>
        <td>{tableData.active_user_rate_budget}</td>
        <td>{tableData.active_user_rate_rate_year_on_year}</td>
        <td>{tableData.transactive_users_actual}</td>
        <td>{tableData.transactive_users_budget}</td>
        <td>{tableData.transactive_users_rate_year_on_year}</td>
        <td>{tableData.transactive_users_price_actual}</td>
        <td>{tableData.transactive_users_price_budget}</td>
        <td>{tableData.transactive_users_price_rate_year_on_year}</td>
        <td>{tableData.transactive_users_gmv_actual}</td>
        <td>{tableData.transactive_users_gmv_budget}</td>
        <td>{tableData.transactive_users_gmv_rate_year_on_year}</td>
        <td>{tableData.users_gmv_percentage_actual}</td>
        <td>{tableData.users_gmv_percentage_budget}</td>
        <td>{tableData.users_gmv_percentage_rate_year_on_year}</td>
      </tr>
    </>
  )
}

Crm.propTypes = {
  tableData: shape({}).isRequired
}

export default Crm
