import React from 'react'
import Actions from './Actions'
import ProgressBar from './ProgressBar'

export const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    align: 'center'
  },
  {
    title: 'Start time',
    dataIndex: 'start_time',
    key: 'start_time',
    align: 'center'
  },
  {
    title: 'End time',
    dataIndex: 'end_time',
    key: 'end_time',
    align: 'center'
  },
  {
    title: 'Initial amount',
    dataIndex: 'initial_amount',
    key: 'initial_amount',
    align: 'center'
  },
  {
    title: 'Progress',
    dataIndex: '',
    key: 'p',
    align: 'center',
    width: 300,
    render: (record) => <ProgressBar record={record} />
  },
  {
    title: 'Enabled',
    dataIndex: 'enabled',
    key: 'enabled',
    width: 100,
    align: 'center'
  },
  {
    title: 'Action',
    dataIndex: '',
    width: 200,
    align: 'center',
    key: 'x',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
