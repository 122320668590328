import {
  SET_GLOBAL_LOADING,
  SET_GLOBAL_CONFIG,
  SET_TABLE_LOADING,
  SET_CURRENT_SCOPE,
  SET_SCOPES,
  SET_CURRENT_COUNTRY
} from '../actions/global'
import { storage } from '../../utils'

const initState = {
  isGlobalLoading: false,
  isTableLoading: false,
  config: storage.config.val ?? [],
  allScopes: storage.allScopes.val ?? [],
  currentScope: storage.currentScope.val || {
    key: 'xpark',
    name: 'XPARK',
    prefix: 'XPARK'
  },
  scopes: [
    { key: 'xpark', name: 'XPARK', prefix: 'XPARK' },
    { key: 'tecno', name: 'Tecno', prefix: 'Tecno' }
  ],
  country_country: '',
  i18n_schemas: storage.i18n_schemas.val ?? {},
  modular: storage.modular.val ?? ''
}

export default (state = initState, action) => {
  switch (action.type) {
    case SET_GLOBAL_LOADING:
      return {
        ...state,
        isGlobalLoading: action.payload.isLoading
      }
    case SET_TABLE_LOADING:
      return {
        ...state,
        isTableLoading: action.payload.isTableLoading
      }
    case SET_GLOBAL_CONFIG:
      return {
        ...state,
        config: action.payload.config.country_config,
        allScopes: action.payload.config.scopes,
        i18n_schemas: action.payload.config.i18n_schemas
      }
    case SET_CURRENT_SCOPE:
      return {
        ...state,
        currentScope: action.payload
      }
    case SET_SCOPES:
      return {
        ...state,
        scopes: action.payload
      }
    case SET_CURRENT_COUNTRY:
      return {
        ...state,
        current_country: action.payload
      }
    default:
      return state
  }
}
