import { storage, getStaticText } from '../../utils'

export const SET_GLOBAL_LOADING = 'SET_GLOBAL_LOADING'
export const SET_GLOBAL_CONFIG = 'SET_GLOBAL_CONFIG'
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING'
export const SET_CURRENT_SCOPE = 'SET_CURRENT_SCOPE'
export const SET_SCOPES = 'SET_SCOPES'
export const SET_CURRENT_COUNTRY = 'SET_CURRENT_COUNTRY'

export const setGlobalLoading = (isLoading) => ({
  type: SET_GLOBAL_LOADING,
  payload: {
    isLoading
  }
})
export const setGlobalConfig = (config) => {
  storage.config.val = config.country_config
  const patterns = config.patterns.map((pattern) => {
    return {
      label: pattern,
      value: pattern,
      disabled: false
    }
  })
  patterns.unshift({
    label: getStaticText('All_Pattern'),
    value: 'All_Pattern',
    disabled: false
  })
  storage.allowedPatterns.val = patterns

  const countries = []
  const data = storage.userInfo.val
  if (data) {
    data.user.allowed_countries
      .map((country) =>
        config.country_config.filter((item) => item.countryCode === country)
      )
      .flat()
      .map((item) =>
        countries.push({
          label: getStaticText(item.countryCode),
          value: item.countryCode,
          disabled: false
        })
      )
    countries.unshift({
      label: getStaticText('All_Country'),
      value: 'All_Country',
      disabled: false
    })
    const platforms = config.country_config.reduce((obj, item) => {
      item.platforms.forEach((a, b) => {
        item.platforms.splice(b, 1, {
          label: a,
          value: a,
          disabled: false
        })
      })
      item.platforms.unshift({
        label: getStaticText('All_Platform'),
        value: 'All_Platform',
        disabled: false
      })
      obj[item.countryCode] = item.platforms
      return obj
    }, {})

    storage.allowedPlatforms.val = platforms
    storage.allowedCountries.val = countries
  }
  storage.allScopes.val = config.scopes
  storage.i18n_schemas.val = config.i18n_schemas
  return {
    type: SET_GLOBAL_CONFIG,
    payload: {
      config
    }
  }
}

export const setTableLoading = (isTableLoading) => ({
  type: SET_TABLE_LOADING,
  payload: {
    isTableLoading
  }
})

export const setCurrentScope = (scope) => {
  storage.currentScope.val = scope
  return {
    type: SET_CURRENT_SCOPE,
    payload: scope
  }
}

export const setScopes = (scopes) => ({
  type: SET_SCOPES,
  payload: scopes
})

export const setCurrentCountry = (country) => ({
  type: SET_CURRENT_COUNTRY,
  payload: country
})
