import React, { useState } from 'react'

import { GridFormDrawer, PagingTable } from '../../../../../components'
import { createColumns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getFortunateList } from '../services'

const FortunateList = () => {
  const [gridData, setGridData] = useState(initGridData)
  const [activityId, setActivityId] = useState(0)
  const [currentRecord, setCurrentRecord] = useState({})

  const paginationHandler = (current, pageSize) => {
    getFortunateList(setGridData)({
      activity_id: activityId,
      current,
      pageSize
    })
  }

  const onOpen = (record) => {
    getFortunateList(setGridData)({ activity_id: record.id })
    setActivityId(record.id)
    setCurrentRecord(record)
  }
  return (
    <>
      <GridFormDrawer type="fortunate" title="中奖记录" onOpen={onOpen}>
        <PagingTable
          handler={paginationHandler}
          columns={createColumns({
            onChange: () => {
              onOpen(currentRecord)
            }
          })}
          dataSource={dataSourceMap(gridData.data)}
          total={gridData.total}
          initGridData={initGridData}
          current={gridData.page}
          pageSize={gridData.page_size}
        />
      </GridFormDrawer>
    </>
  )
}

export default FortunateList
